import { Tooltip } from "flowbite-react";
import { FaExclamationTriangle } from "react-icons/fa";
import React from "react";

export const proximaCelula = (event, passos = 1) => {
    const form = event.target.form;
    const elements = Array.from(form.elements);
    let index = elements.indexOf(event.target);

    // Loop para encontrar o próximo elemento habilitado, baseado no número de passos
    let passosDados = 0;
    while (index < elements.length - 1) {
        index++;
        if (!elements[index].disabled && elements[index].type !== "hidden") {
            passosDados++;
            if (passosDados === passos) {
                elements[index].focus();
                break;
            }
        }
    }
};

export const TooltipErro = ({ item }) => {
    if (!item?.flag_erro) {
        return <></>;
    }

    return (
        <Tooltip content={item.log_erro}>
            <div className="text-white flex items-center justify-center h-full bg-red-700">
                <FaExclamationTriangle />
            </div>
        </Tooltip>
    );
};
