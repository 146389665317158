import { Controller } from "react-hook-form";
import React from "react";
import {
    proximaCelula,
    TooltipErro,
} from "pages/admin/cliente/projetos/subparte/components/utils";
import InputLm from "pages/admin/cliente/projetos/subparte/components/input-lm";

export default function TabelaParafusos({
    fieldParafusos,
    preenchimento,
    campoRevisao,
    form,
}) {
    const { control, watch, setValue } = form;

    const deletarLinha = (e, index) => {
        e.preventDefault();

        const fieldset = e.target.closest("fieldset");
        if (fieldset && fieldset.disabled) {
            return;
        }

        let confirmacao = window.confirm("Deseja deletar a linha selecionada?");
        if (!confirmacao) {
            return;
        }

        if (fieldParafusos.fields.length > 1) {
            fieldParafusos.remove(index);
        } else {
            alert("Não é possível remover a última linha");
        }
    };

    return (
        <table className="table-auto">
            <thead className="header-sticky">
                <tr>
                    <th
                        colSpan={1}
                        className="bg-blue-600 text-white border border-gray-900 font-medium"
                    >
                        PARAFUSOS
                    </th>
                    <th colSpan={4} />
                </tr>
                <tr>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Bitola
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Comprimento
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Quantidade
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Qualidade
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Revisão
                    </th>
                </tr>
            </thead>
            <tbody>
                {fieldParafusos.fields.map((parafuso, index) => {
                    return (
                        <tr
                            key={parafuso.id}
                            className={parafuso.flag_erro ? "erro" : ""}
                        >
                            <td className="flex flex-row">
                                <TooltipErro item={parafuso} />
                                <Controller
                                    name={`parafusos.${index}.dimensao1`}
                                    control={control}
                                    defaultValue={parafuso.dimensao1}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onBlur={(e) => {
                                                if (
                                                    index ===
                                                        fieldParafusos.fields
                                                            .length -
                                                            1 &&
                                                    e.target.value !== ""
                                                ) {
                                                    fieldParafusos.append(
                                                        {},
                                                        { shouldFocus: false },
                                                    );
                                                }
                                            }}
                                            onChange={(e) => {
                                                let value = String(
                                                    e.target.value,
                                                ).toUpperCase();
                                                const simbolos = [
                                                    "-",
                                                    "+",
                                                    ".",
                                                ];

                                                for (let simbolo of simbolos) {
                                                    if (value === simbolo) {
                                                        e.preventDefault();

                                                        let parafuso =
                                                            preenchimento
                                                                ?.parafusos[
                                                                simbolos.indexOf(
                                                                    simbolo,
                                                                )
                                                            ];
                                                        if (!parafuso) {
                                                            continue;
                                                        }

                                                        let qualidade =
                                                            parafuso.parafuso_qualidade;
                                                        let bitola =
                                                            parafuso.parafuso_bitola;
                                                        setValue(
                                                            `parafusos.${index}.qualidade`,
                                                            qualidade,
                                                        );
                                                        setValue(
                                                            `parafusos.${index}.dimensao1`,
                                                            bitola,
                                                        );
                                                        proximaCelula(e);
                                                        return;
                                                    }
                                                }

                                                setValue(
                                                    `parafusos.${index}.dimensao1`,
                                                    value,
                                                );
                                                let parafuso =
                                                    preenchimento?.parafusos?.find(
                                                        (x) =>
                                                            x.parafuso_bitola ===
                                                            value,
                                                    );
                                                let qualidade =
                                                    parafuso?.parafuso_qualidade;

                                                if (!parafuso) {
                                                    return;
                                                }
                                                if (qualidade) {
                                                    setValue(
                                                        `parafusos.${index}.qualidade`,
                                                        qualidade,
                                                    );
                                                }
                                                proximaCelula(e);
                                            }}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`parafusos.${index}.dimensao2`}
                                    control={control}
                                    defaultValue={parafuso.dimensao2}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            required={
                                                !!watch(
                                                    `parafusos.${index}.dimensao1`,
                                                )
                                            }
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`parafusos.${index}.quantidade`}
                                    control={control}
                                    defaultValue={parafuso.quantidade}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onBlur={(e) => {
                                                let valor = e.target.value;
                                                if (
                                                    String(valor).includes(
                                                        "+",
                                                    ) &&
                                                    !String(valor).endsWith("*")
                                                ) {
                                                    setValue(
                                                        `parafusos.${index}.quantidade`,
                                                        valor + "*",
                                                    );
                                                }
                                            }}
                                            required={
                                                !!watch(
                                                    `parafusos.${index}.dimensao1`,
                                                )
                                            }
                                            min={
                                                !!watch(
                                                    `parafusos.${index}.dimensao1`,
                                                )
                                                    ? 1
                                                    : ""
                                            }
                                            type="number"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`parafusos.${index}.qualidade`}
                                    control={control}
                                    defaultValue={parafuso.qualidade}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            required={
                                                !!watch(
                                                    `parafusos.${index}.dimensao1`,
                                                )
                                            }
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`parafusos.${index}.rev`}
                                    control={control}
                                    defaultValue={parafuso.rev}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            disabled={!campoRevisao}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
