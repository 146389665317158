import { Breadcrumb } from "flowbite-react";
import React from "react";
import { settings } from "settings";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import Icon from "../icon";

export default function Breadcrumbs({
    title,
    breadcrumbs = [],
    home = "/admin",
}) {
    document.title = settings.TITULO_SISTEMA + " :: " + title;

    return (
        <div className="pl-5 bg-gray-50 flex items-center">
            <span className="mr-5 text-gray-600 font-medium">{title}</span>
            <Breadcrumb className="py-3">
                <Breadcrumb.Item>
                    <Link to={home} className="text-gray-400 flex items-center">
                        <Icon className="mr-2">
                            <FaHome />
                        </Icon>
                        Início
                    </Link>
                </Breadcrumb.Item>
                {breadcrumbs.map((item, i) => {
                    if (!item.url) {
                        return (
                            <Breadcrumb.Item key={i}>
                                <span className="text-blue-700">
                                    {item.label}
                                </span>
                            </Breadcrumb.Item>
                        );
                    }

                    return (
                        <Breadcrumb.Item key={i}>
                            <Link to={item.url}>{item.label}</Link>
                        </Breadcrumb.Item>
                    );
                })}
            </Breadcrumb>
        </div>
    );
}
