import React from "react";
import { Dropdown, Navbar } from "flowbite-react";
import { Link } from "react-router-dom";
import Icon from "../icon";
import { settings } from "settings";
import { HiChevronDown } from "react-icons/hi";
import { Avatar } from "flowbite-react";
import { useAuth } from "../../hooks/auth";

const Header = ({ menu = [], userMenu = [] }) => {
    const { user } = useAuth();

    return (
        <div className="bg-gray-800 text-white">
            <div className="flex items-center justify-between px-5 py-2">
                <div className="flex items-center">
                    <img
                        src={settings.LOGO_SISTEMA}
                        alt="Logo"
                        className="h-10 mr-3"
                    />
                    <h1 className="text-lg font-semibold">
                        {settings.TITULO_SISTEMA_DISPLAY}
                    </h1>
                </div>
                <div className="flex items-center space-x-4">
                    <div>
                        <Dropdown
                            placement="bottom"
                            label="menu-usuario"
                            renderTrigger={() => (
                                <div className="cursor-pointer">
                                    <Avatar
                                        placeholderInitials={
                                            user.avatar_iniciais
                                        }
                                        rounded
                                    />
                                </div>
                            )}
                        >
                            <Dropdown.Item>
                                <div className="flex flex-col items-start">
                                    <span className="text-gray-500 text-xs">
                                        Email:
                                    </span>
                                    <span>{user.email}</span>
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            {userMenu.map((item, i) => {
                                if (item?.hidden) {
                                    return <React.Fragment key={i} />;
                                }

                                return (
                                    <Dropdown.Item
                                        key={i}
                                        as={Link}
                                        to={item.url}
                                    >
                                        <div className="flex items-center">
                                            <Icon className="mr-2">
                                                {item.icon}
                                            </Icon>
                                            <span className="text-gray-600">
                                                {item.label}
                                            </span>
                                        </div>
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown>
                    </div>
                </div>
            </div>
            <Navbar fluid className="md:mx-5 rounded-t">
                <div className="flex md:order-2">
                    <Navbar.Toggle />
                </div>
                <Navbar.Collapse className="py-2 md:ml-5">
                    {menu.map((item, i) => {
                        if (item?.hidden) {
                            return <React.Fragment key={i} />;
                        }

                        if (item.items) {
                            return (
                                <Dropdown
                                    key={i}
                                    inline
                                    label=""
                                    dismissOnClick={false}
                                    renderTrigger={() => (
                                        <li>
                                            <Link>
                                                <div className="flex items-center block py-2 pl-3 pr-4 md:p-0 border-b border-gray-100 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:dark:hover:bg-transparent md:dark:hover:text-white md:hover:text-yellow-400">
                                                    <Icon className="mr-2">
                                                        {item.icon}
                                                    </Icon>
                                                    <span className="text-gray-600">
                                                        {item.label}
                                                    </span>
                                                    <HiChevronDown className="ml-1 pt-1 text-lg" />
                                                </div>
                                            </Link>
                                        </li>
                                    )}
                                >
                                    {item.items.map((subItem, j) => {
                                        if (subItem?.hidden) {
                                            return <React.Fragment key={j} />;
                                        }

                                        return (
                                            <Dropdown.Item
                                                key={j}
                                                className="w-48 ml-1"
                                            >
                                                <Link
                                                    to={subItem.url}
                                                    className="flex items-center py-2"
                                                >
                                                    <Icon className="mr-2">
                                                        {subItem.icon}
                                                    </Icon>
                                                    <span className="text-gray-500">
                                                        {subItem.label}
                                                    </span>
                                                </Link>
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown>
                            );
                        }

                        return (
                            <Navbar.Link
                                key={i}
                                as={Link}
                                to={item.url}
                                className="md:hover:text-blue-700"
                            >
                                <div className="flex items-center">
                                    <Icon className="mr-2">{item.icon}</Icon>
                                    <span className="text-gray-600">
                                        {item.label}
                                    </span>
                                </div>
                            </Navbar.Link>
                        );
                    })}
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default Header;
