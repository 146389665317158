import React, { createContext, useCallback, useState } from "react";
import { Toast } from "flowbite-react";

const ToastContext = createContext({});

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const addToast = useCallback((content) => {
        const id = Date.now();
        setToasts((prevToasts) => [...prevToasts, { id, content }]);
        setTimeout(() => {
            removeToast(id);
        }, 2000);
    }, []);

    const removeToast = useCallback((id) => {
        setToasts((prevToasts) =>
            prevToasts.filter((toast) => toast.id !== id),
        );
    }, []);

    return (
        <ToastContext.Provider value={addToast}>
            {children}
            <div className="fixed bottom-4 right-4 flex flex-col space-y-2">
                {toasts.map((toast, i) => (
                    <div key={i}>{toast.content}</div>
                ))}
            </div>
        </ToastContext.Provider>
    );
};

export default ToastContext;
