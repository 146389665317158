import { route, urls } from "../urls";
import api from "../utils/api";

export default class ProjetoSubparteRascunho {
    constructor(id_cliente, id_projeto, id_subparte = 0) {
        this.id_subparte = id_subparte || 0;
        this.url_params = {
            id_cliente: id_cliente,
            id_projeto: id_projeto,
            id_subparte: id_subparte,
        };
    }

    async post(values) {
        const url = route(
            urls.api.admin.cliente.projeto.subparte.salvar_rascunho,
            this.url_params,
        );
        const { data } = await api.post(url, values);
        return data;
    }
}
