import AdminLayout from "layout/admin-layout";
import Breadcrumbs from "layout/admin/breadcrumbs";
import React, { useState } from "react";
import { route, urls } from "../../../../../urls";
import { useNavigate, useParams } from "react-router";
import { Button, FileInput } from "flowbite-react";
import { useForm } from "react-hook-form";
import ProjetoImportar from "../../../../../services/projeto_importar";
import Panel from "../../../../../layout/form/panel";
import ProjetoLayout from "../../../../../layout/projeto-layout";
import { useToast } from "../../../../../hooks/toast";
import ToastErro from "../../../../../layout/toast/toast-erro";
import ToastSucesso from "../../../../../layout/toast/toast-sucesso";

export default function ProjetoImportarPage() {
    const { id_cliente, id_projeto } = useParams();
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();
    const toast = useToast();

    const onSubmit = async (data) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("arquivo", data.arquivo[0]);

        new ProjetoImportar(id_cliente, id_projeto)
            .save(formData)
            .then((data) => {
                if (data?.erros.length > 0) {
                    toast(
                        <ToastErro msg="Os itens foram importados porém foram encontrados os seguintes erros" />,
                    );
                    navigate(
                        route(urls.web.admin.cliente.projeto.erros, {
                            id_cliente: id_cliente,
                            id_projeto: id_projeto,
                        }),
                    );
                } else {
                    toast(
                        <ToastSucesso msg="Os itens foram importados com sucesso." />,
                    );
                    navigate(
                        route(urls.web.admin.cliente.projeto.subparte.listar, {
                            id_cliente: id_cliente,
                            id_projeto: id_projeto,
                        }),
                    );
                }
            })
            .catch(() => {
                alert("Ocorreu um erro ao importar");
            });
    };

    return (
        <AdminLayout>
            <ProjetoLayout active="importar">
                <Breadcrumbs
                    title="Projetos"
                    breadcrumbs={[
                        {
                            label: "Clientes",
                            url: route(urls.web.admin.cliente.listar, {
                                id_cliente: id_cliente,
                            }),
                        },
                        {
                            label: "Projetos",
                            url: route(urls.web.admin.cliente.projeto.listar, {
                                id_cliente: id_cliente,
                            }),
                        },
                        {
                            label: "Importar Projeto",
                            url: "",
                        },
                    ]}
                />

                <div className="container mx-auto max-w-5xl">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        encType="multipart/form-data"
                    >
                        <Panel title="Importação de lista">
                            <FileInput
                                required
                                type="arquivo"
                                {...register("arquivo")}
                            />
                            <div className="flex mt-5 justify-center">
                                <Button
                                    isProcessing={loading}
                                    type={!loading ? "submit" : "button"}
                                    color="blue"
                                >
                                    Enviar
                                </Button>
                            </div>
                        </Panel>
                    </form>
                </div>
            </ProjetoLayout>
        </AdminLayout>
    );
}
