import React from "react";
import { Link } from "react-router-dom";
import BarraFerramentas from "layout/listagem/barra-ferramentas";
import BotaoListagem from "layout/listagem/botao-listagem";
import TabelaColuna from "layout/listagem/tabela-coluna";
import TabelaListagem from "layout/listagem/tabela-listagem";
import Breadcrumbs from "layout/admin/breadcrumbs";
import AdminLayout from "layout/admin-layout";
import { route, urls } from "../../../../../urls";
import { Avatar, Badge, Button } from "flowbite-react";
import { ListagemProvider } from "../../../../../context/listagem-context";
import { FaArrowDown, FaArrowUp, FaPlusCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import ProjetoSubparte from "../../../../../services/projeto_subparte";
import ListLink from "../../../../../layout/listagem/list-link";
import ProjetoLayout from "../../../../../layout/projeto-layout";
import { refreshQuery } from "../../../../../utils/query";

function SubparteListPage() {
    const { id_cliente, id_projeto } = useParams();

    return (
        <AdminLayout>
            <ProjetoLayout active="subpartes">
                <ListagemProvider
                    source={new ProjetoSubparte(id_cliente, id_projeto)}
                >
                    <Breadcrumbs
                        title="Subpartes"
                        breadcrumbs={[
                            {
                                label: "Clientes",
                                url: route(urls.web.admin.cliente.listar),
                            },
                            {
                                label: "Projetos",
                                url: route(
                                    urls.web.admin.cliente.projeto.listar,
                                    {
                                        id_cliente: id_cliente,
                                    },
                                ),
                            },
                            {
                                label: "Subpartes",
                                url: "",
                            },
                        ]}
                    />
                    <Listagem />
                </ListagemProvider>
            </ProjetoLayout>
        </AdminLayout>
    );
}

const Listagem = () => {
    const { id_cliente, id_projeto } = useParams();
    let navigate = useNavigate();

    return (
        <div className="p-4 bg-white">
            <BarraFerramentas>
                <BotaoListagem
                    url={route(urls.web.admin.cliente.projeto.subparte.criar, {
                        id_cliente,
                        id_projeto,
                    })}
                    label="Nova Subparte"
                    icon={<FaPlusCircle />}
                />
            </BarraFerramentas>
            <div className="mt-5 overflow-x-auto">
                <TabelaListagem>
                    <TabelaColuna
                        label="Nome"
                        name="nome"
                        align="L"
                        callback={(row) => {
                            return (
                                <ListLink
                                    to={route(
                                        urls.web.admin.cliente.projeto.subparte
                                            .itens,
                                        {
                                            id_cliente,
                                            id_projeto,
                                            id_subparte: row.id,
                                        },
                                    )}
                                >
                                    {row.nome}
                                </ListLink>
                            );
                        }}
                    />
                    <TabelaColuna label="Desenho" name="desenho" />
                    <TabelaColuna label="Revisão" name="revisao" />
                    <TabelaColuna label="Sigla" name="sigla" />
                    <TabelaColuna
                        size="sm"
                        label="Situação"
                        name="flag_erro"
                        callback={(row) => {
                            if (row?.usuario) {
                                return (
                                    <div className="flex flex-row">
                                        <Avatar
                                            placeholderInitials={
                                                row.usuario.avatar_iniciais
                                            }
                                            size="sm"
                                            rounded
                                        />
                                        <div className="flex flex-col ml-4">
                                            <span className="font-medium">
                                                {row.usuario.email}
                                            </span>
                                            <span>
                                                Está editando esta subparte
                                            </span>
                                        </div>
                                    </div>
                                );
                            }

                            if (row.flag_erro > 0) {
                                return (
                                    <Badge color="failure">
                                        Itens com erro
                                    </Badge>
                                );
                            }
                            return <Badge color="success">OK</Badge>;
                        }}
                    />
                    <TabelaColuna
                        align="C"
                        label="Preenchimento"
                        name="preenchimento"
                        callback={(row) => {
                            return (
                                <div className="flex justify-center">
                                    <Link
                                        to={route(
                                            urls.web.admin.cliente.projeto
                                                .subparte.editar,
                                            {
                                                id_cliente: id_cliente,
                                                id_projeto: id_projeto,
                                                id_subparte: row.id,
                                            },
                                        )}
                                    >
                                        <Button color="gray" size="xs">
                                            Editar Subparte
                                        </Button>
                                    </Link>
                                </div>
                            );
                        }}
                    />

                    <TabelaColuna
                        align="C"
                        label="Ordem"
                        name="ordem"
                        callback={(row) => {
                            return (
                                <div className="flex justify-center">
                                    <button
                                        type="button"
                                        className="bg-gray-200 text-gray-700 p-2"
                                        onClick={() => {
                                            new ProjetoSubparte(
                                                id_cliente,
                                                id_projeto,
                                                row.id,
                                            )
                                                .alterar_ordem("-")
                                                .then(() => {
                                                    refreshQuery(navigate);
                                                });
                                        }}
                                    >
                                        <FaArrowUp />
                                    </button>
                                    <button
                                        type="button"
                                        className="border text-gray-600 p-2"
                                        onClick={() => {
                                            new ProjetoSubparte(
                                                id_cliente,
                                                id_projeto,
                                                row.id,
                                            )
                                                .alterar_ordem("+")
                                                .then(() => {
                                                    refreshQuery(navigate);
                                                });
                                        }}
                                    >
                                        <FaArrowDown />
                                    </button>
                                </div>
                            );
                        }}
                    />
                </TabelaListagem>
            </div>
        </div>
    );
};

export default SubparteListPage;
