import Header from "./admin/header";
import {
    FaBuilding,
    FaKey,
    FaSignOutAlt,
    FaUser,
    FaUsers,
} from "react-icons/fa";
import { route, urls } from "../urls";
import { useAuth } from "../hooks/auth";

function AdminLayout({ children }) {
    const auth = useAuth();

    const menu = [
        {
            label: "Clientes",
            url: route(urls.web.admin.cliente.listar),
            icon: <FaBuilding />,
        },
        {
            label: "Usuários",
            icon: <FaUsers />,
            url: route(urls.web.admin.usuario.listar),
            hidden: +auth.user.tipo_usuario !== 1,
        },
    ];

    let userMenu = [
        {
            label: "Meus Dados",
            url: "/admin/usuario/meu-perfil",
            icon: <FaUser />,
        },
        {
            label: "Sair",
            url: "/admin/logout",
            icon: <FaSignOutAlt />,
        },
    ];

    return (
        <div className="flex flex-col h-full">
            <Header menu={menu} userMenu={userMenu} />
            <main className="bg-gray-100 flex-1">
                <div className="bg-gray-50 md:mx-5 border-l border-r border-b h-full">
                    {children}
                </div>
            </main>
        </div>
    );
}

export default AdminLayout;
