import { route, urls } from "../urls";
import api from "../utils/api";

export default class ProjetoParte {
    constructor(id_cliente, id_projeto, id_parte = 0) {
        this.id_parte = id_parte || 0;
        this.url_params = {
            id_cliente: id_cliente,
            id_projeto: id_projeto,
            id_parte: id_parte,
        };
    }

    async list(parameters) {
        const url = route(
            urls.api.admin.cliente.projeto.parte.index,
            this.url_params,
        );
        const { data } = await api.get(url, { params: parameters });
        return data;
    }

    async options() {
        const url = route(
            urls.api.admin.cliente.projeto.parte.index,
            this.url_params,
        );
        const { data } = await api.options(url);
        return data;
    }

    async alterar_ordem(ordem) {
        const url = route(
            urls.api.admin.cliente.projeto.parte.alterar_ordem,
            this.url_params,
        );
        const { data } = await api.post(url, {
            ordem: ordem,
        });
        return data;
    }

    async show() {
        const url = route(
            urls.api.admin.cliente.projeto.parte.edit,
            this.url_params,
        );
        const { data } = await api.get(url);
        return data;
    }

    async update(values) {
        const url = route(
            urls.api.admin.cliente.projeto.parte.edit,
            this.url_params,
        );
        const { data } = await api.patch(url, values);
        return data;
    }

    async save(values) {
        if (this.id_parte > 0) {
            return this.update(values);
        }
        return this.create(values);
    }

    async create(values) {
        const url = route(
            urls.api.admin.cliente.projeto.parte.index,
            this.url_params,
        );
        const { data } = await api.post(url, values);
        return data;
    }

    async delete() {
        const url = route(
            urls.api.admin.cliente.projeto.parte.edit,
            this.url_params,
        );
        const { data } = await api.delete(url);
        return data;
    }
}
