import { Button } from "flowbite-react";
import React from "react";
import useCrud from "../../hooks/crud";
import { HiTrash } from "react-icons/hi";

export default function BotoesSubmit() {
    const { goBack, saving, form, destroyItem } = useCrud();

    const abrirModalDeletar = () => {
        let confirmacao = window.confirm(
            "Deseja deletar o item? (Esta operação não pode ser desfeita)",
        );
        if (!confirmacao) {
            return;
        }

        destroyItem();
    };

    if (saving) {
        return (
            <div className="my-3 space-y-0 flex flex-col items-center lg:flex-row md:space-x-4">
                <Button
                    disabled={true}
                    color="gray"
                    className="sm:w-full md:w-auto"
                >
                    Salvar e Continuar
                </Button>
                <Button
                    disabled={true}
                    color="blue"
                    className="sm:w-full md:w-auto"
                >
                    Salvar
                </Button>
                <button
                    disabled={true}
                    type="button" onClick={goBack} className="md:pl-3">
                    Voltar
                </button>
            </div>
        );
    }

    return (
        <div className="my-3 space-y-0 flex flex-col items-center lg:flex-row md:space-x-4">
            <Button
                onClick={(e) => form.setValue("_redir", "list")}
                type="submit"
                color="blue"
                className="sm:w-full md:w-auto"
            >
                Salvar
            </Button>
            <Button
                onClick={(e) => form.setValue("_redir", "edit")}
                type="submit"
                color="gray"
                className="sm:w-full md:w-auto"
            >
                Salvar e Continuar
            </Button>
            <button type="button" onClick={goBack} className="md:pl-3">
                Voltar
            </button>
            <Button type="button" color="red" onClick={abrirModalDeletar}>
                <div className="flex items-center">
                    <HiTrash size={15} /> <span className="ml-2">Deletar</span>
                </div>
            </Button>
        </div>
    );
}
