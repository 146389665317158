import React, { useEffect, useState } from "react";
import BarraFerramentas from "layout/listagem/barra-ferramentas";
import BotaoListagem from "layout/listagem/botao-listagem";
import Breadcrumbs from "layout/admin/breadcrumbs";
import AdminLayout from "layout/admin-layout";
import { route, urls } from "../../../../../urls";
import Projeto from "../../../../../services/projeto";
import { ListagemProvider } from "../../../../../context/listagem-context";
import { FaPlusCircle } from "react-icons/fa";
import { useParams } from "react-router";
import useListagem from "../../../../../hooks/listagem";
import ListLink from "../../../../../layout/listagem/list-link";
import TabelaListagem from "../../../../../layout/listagem/tabela-listagem";
import TabelaColuna from "../../../../../layout/listagem/tabela-coluna";
import { useAuth } from "../../../../../hooks/auth";

function ProjetoListPage() {
    const titulo = "Lista de Projetos";
    const { id_cliente } = useParams();

    return (
        <AdminLayout>
            <ListagemProvider source={new Projeto(id_cliente)}>
                <BreadcrumbsListagem />
                <Listagem />
            </ListagemProvider>
        </AdminLayout>
    );
}

const BreadcrumbsListagem = () => {
    const { id_cliente } = useParams();
    const [titulo, setTitulo] = useState("Listagem");
    const { data } = useListagem();

    useEffect(() => {
        const { extra } = data;
        const { cliente } = extra;
        setTitulo(cliente.nome + " - Projetos");
    }, [data]);

    return (
        <Breadcrumbs
            title={titulo}
            breadcrumbs={[
                {
                    label: "Clientes",
                    url: route(urls.web.admin.cliente.listar),
                },
                {
                    label: "Projetos",
                    url: "",
                },
            ]}
        />
    );
};

const Listagem = () => {
    const { id_cliente } = useParams();

    const { user } = useAuth();

    return (
        <div className="p-4 bg-white">
            <BarraFerramentas>
                <BotaoListagem
                    url={route(urls.web.admin.cliente.projeto.criar, {
                        id_cliente: id_cliente,
                    })}
                    hidden={+user.tipo_usuario !== 1}
                    label="Novo Projeto"
                    icon={<FaPlusCircle />}
                />
            </BarraFerramentas>
            <div className="mt-5 overflow-x-auto">
                <TabelaListagem>
                    <TabelaColuna
                        label="ID"
                        size="xs"
                        name="id"
                        callback={(row) => {
                            return (
                                <ListLink
                                    to={route(
                                        urls.web.admin.cliente.projeto.subparte
                                            .listar,
                                        {
                                            id_cliente: id_cliente,
                                            id_projeto: row.id,
                                        },
                                    )}
                                >
                                    {row.id}
                                </ListLink>
                            );
                        }}
                    />
                    <TabelaColuna label="OS" name="os" />
                    <TabelaColuna label="Estrutura" name="estrutura" />
                    <TabelaColuna label="Obra" name="obra" />
                    <TabelaColuna label="Nº Projeto" name="numero_projeto" />
                    <TabelaColuna label="Sigla" name="sigla" />
                    <TabelaColuna label="Revisão" name="revisao" />
                    <TabelaColuna label="Data" name="data" />
                </TabelaListagem>
            </div>
        </div>
    );
};

export default ProjetoListPage;
