import { TextInput } from "flowbite-react";
import { updateQuery, getQuery } from "../../utils/query";
import React from "react";
import { useNavigate } from "react-router";

export default function InputBusca() {
    let navigate = useNavigate();

    return (
        <TextInput
            defaultValue={getQuery("q")}
            onKeyDown={(e) => {
                if (+e.keyCode === 13) {
                    updateQuery(navigate, "q", e.target.value);
                }
            }}
            name="q"
            placeholder="Buscar..."
            className="mr-3"
        />
    );
}
