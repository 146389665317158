import React from "react";
import { Pagination, Select } from "flowbite-react";
import { getQuery, updateQuery } from "../../utils/query";
import useListagem from "../../hooks/listagem";
import { useNavigate } from "react-router";

export default function Paginacao() {
    let navigate = useNavigate();

    const handlePageChange = (page) => {
        updateQuery(navigate, "page", page);
    };

    const { data } = useListagem();

    let pagination = data?.pagination || [];
    let { current, total } = pagination;

    return (
        <div className="flex overflow-x-auto items-center justify-between mt-4">
            <Pagination
                previousLabel="Anterior"
                nextLabel="Próximo"
                currentPage={current}
                totalPages={total}
                onPageChange={handlePageChange}
                showIcons
            />
            <div className="flex space-x-2 items-center">
                <label>Por Página</label>
                <Select
                    defaultValue={getQuery("per_page")}
                    onChange={(e) =>
                        updateQuery(navigate, "per_page", e.target.value)
                    }
                >
                    <option value="20" label="20" />
                    <option value="40" label="40" />
                    <option value="60" label="60" />
                    <option value="100" label="100" />
                    <option value="200" label="200" />
                </Select>
            </div>
        </div>
    );
}
