import ProjetoLayout from "../../../../../layout/projeto-layout";
import Panel from "../../../../../layout/form/panel";
import AdminLayout from "../../../../../layout/admin-layout";
import Breadcrumbs from "../../../../../layout/admin/breadcrumbs";
import { route, urls } from "../../../../../urls";
import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

export default function ProjetoExportarPage() {
    const { id_cliente, id_projeto } = useParams();

    return (
        <AdminLayout>
            <ProjetoLayout active="exportar">
                <Breadcrumbs
                    title="Exportar Projeto"
                    breadcrumbs={[
                        {
                            label: "Clientes",
                            url: route(urls.web.admin.cliente.listar),
                        },
                        {
                            label: "Projetos",
                            url: route(urls.web.admin.cliente.projeto.listar, {
                                id_cliente: id_cliente,
                            }),
                        },
                        {
                            label: "Exportar",
                            url: "",
                        },
                    ]}
                />

                <div className="mx-5 mt-3">
                    <Panel title="Exportar">
                        <div className="d-flex flex-col gap-4 m-5">
                            <li>Relatório de Conferência</li>
                            <li className="text-blue-600">
                                <Link
                                    to={route(
                                        urls.web.admin.cliente.projeto.exportar
                                            .lm_excel,
                                        {
                                            id_cliente,
                                            id_projeto,
                                        },
                                    )}
                                >
                                    Lista de Materiais (Excel)
                                </Link>
                            </li>
                            <li>Lista de Parafusos (Excel)</li>
                            <li className="text-blue-600">
                                <Link
                                    to={route(
                                        urls.web.admin.cliente.projeto.exportar
                                            .lm_txt,
                                        {
                                            id_cliente,
                                            id_projeto,
                                        },
                                    )}
                                >
                                    Lista de Materiais (TXT)
                                </Link>
                            </li>
                        </div>
                    </Panel>
                </div>
            </ProjetoLayout>
        </AdminLayout>
    );
}
