import { route, urls } from "../urls";
import api from "../utils/api";

export default class ProjetoPreenchimento {
    constructor(id_cliente, id_projeto = 0) {
        this.url_params = { id_cliente: id_cliente, id_projeto: id_projeto };
    }

    async show() {
        const url = route(
            urls.api.admin.cliente.projeto.preenchimento,
            this.url_params,
        );
        const { data } = await api.get(url);
        return data;
    }

    async save(values) {
        const url = route(
            urls.api.admin.cliente.projeto.preenchimento,
            this.url_params,
        );
        const { data } = await api.post(url, values);
        return data;
    }
}
