import { getToken } from "../utils/token-auth";
import api from "../utils/api";
import { route, urls } from "../urls";

export default class UsuarioLogin {
    async me() {
        if (!getToken()) {
            throw true;
        }

        const url = route(urls.api.admin.auth.me);
        const { data } = await api.get(url);
        return data;
    }

    async login(values) {
        const url = route(urls.api.admin.auth.login);
        delete api.defaults.headers.common.Authorization;
        const { data } = await api.post(url, {
            email: values.email,
            senha: values.password,
        });
        return data;
    }
}
