import { Controller } from "react-hook-form";
import Select from "react-select";
import React from "react";
import { Label, Tooltip } from "flowbite-react";
import { FaInfoCircle } from "react-icons/fa";
import useCrud from "../../hooks/crud";
import ErrorField from "./error-field";

export default function MultiSelectField({
    name,
    disabled,
    help,
    multiple = true,
    defaultValue,
    required = false,
    label = "",
    size = "md",
    options = [],
    ...props
}) {
    let id = "f-" + name;

    const { form } = useCrud();
    const { control, setValue } = form;

    const customOnChange = (newValue) => {
        let tempNewValue = [];
        for (let item of newValue) {
            tempNewValue.push(item.value);
        }
        setValue(name, tempNewValue);
    };

    return (
        <div className="mb-4">
            <div className="flex mb-1 text-sm">
                <Label htmlFor={id} value={label} className="text-gray-600" />
                {required && (
                    <span className="text-red-500 font-bold ml-1">*</span>
                )}
                {help && (
                    <Tooltip content={help}>
                        <FaInfoCircle className="text-blue-600 ml-1 text-sm" />
                    </Tooltip>
                )}
            </div>
            <div>
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => {
                        let valueField = field.value;
                        let valueF = [];
                        if (!valueField) {
                            valueField = [];
                        }
                        for (let item of options) {
                            if (valueField.includes(item.value) > 0) {
                                valueF.push(item);
                            }
                        }

                        return (
                            <Select
                                {...field}
                                options={options}
                                value={valueF}
                                isMulti
                                onChange={customOnChange}
                                classNamePrefix="react-select"
                            />
                        );
                    }}
                />
            </div>
            <ErrorField name={name} />
        </div>
    );
}
