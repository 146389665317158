import React from "react";
import { FileInput, Label, Tooltip } from "flowbite-react";

import { FaInfoCircle } from "react-icons/fa";
import ErrorField from "./error-field";
import useCrud from "../../hooks/crud";

export default function FileField({
    required = false,
    help,
    label = "",
    name,
    maxSize = 3,
    ...props
}) {
    const { form } = useCrud();

    let id = "f-" + name;

    const handleFileChange = (event) => {
        form.setValue(name + "_base64", "aaaaaa");
        const file = event.target.files[0];
        if (file) {
            if (file.size > maxSize * 1024 * 1024) {
                alert(`O arquivo é maior que ${maxSize}MB`);
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = reader.result;
                form.setValue(name + "_base64", base64);
            };
            reader.readAsDataURL(file);
        }
    };

    let url = form.getValues(name);
    const isImage = url && /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);

    return (
        <div className="mb-5">
            <div className="flex mb-1 text-sm">
                <Label htmlFor={id} value={label} className="text-gray-600" />
                {required && (
                    <span className="text-red-500 font-bold ml-1">*</span>
                )}
                {help && (
                    <Tooltip content={help}>
                        <FaInfoCircle className="text-blue-600 ml-1 text-sm" />
                    </Tooltip>
                )}
            </div>
            <input
                className="hidden"
                {...form.register}
                name={name + "_base64"}
            />
            <FileInput
                onChange={handleFileChange}
                id={id}
                required={required}
                {...props}
            />
            <ErrorField name={name} />
            {url &&
                (isImage ? (
                    <img src={url} alt="Preview" className="mt-2" width={200} />
                ) : (
                    <a
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                        className="mt-2 text-sm text-blue-500 font-medium"
                    >
                        Ver aquivo
                    </a>
                ))}
        </div>
    );
}
