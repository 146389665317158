import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PaginaLogin from "pages/auth/pagina-login";
import ProjetoListPage from "pages/admin/cliente/projetos/projeto/list";
import ProjetoCrudPage from "pages/admin/cliente/projetos/projeto/crud";
import ParteListPage from "pages/admin/cliente/projetos/parte/list";
import ParteCrudPage from "pages/admin/cliente/projetos/parte/crud";
import ClienteListPage from "pages/admin/cliente/list";
import ClienteCrudPage from "pages/admin/cliente/crud";
import ProjetoPreenchimentoPage from "pages/admin/cliente/projetos/projeto/preenchimento";
import SubparteListPage from "pages/admin/cliente/projetos/subparte/list";
import SubparteCrudPage from "pages/admin/cliente/projetos/subparte/crud";
import { Flowbite } from "flowbite-react";
import AuthContextProvider from "./context/auth-context";
import { ToastProvider } from "./context/toast-context";
import ProjetoImportarPage from "pages/admin/cliente/projetos/projeto/importar";
import SubparteItensPage from "pages/admin/cliente/projetos/subparte/itens";
import ProjetoErrosPage from "pages/admin/cliente/projetos/projeto/erros";
import ProjetoExportarPage from "pages/admin/cliente/projetos/projeto/exportar";
import ExportarLmExcelPage from "pages/admin/cliente/projetos/projeto/exportar/lm-excel";
import UsuarioCrudPage from "pages/admin/usuario/crud";
import UsuarioListPage from "pages/admin/usuario/list";
import LogoutPage from "pages/admin/logout";
import MeuPerfilPage from "pages/admin/usuario/meu-perfil";
import ExportarLmTxtPage from "pages/admin/cliente/projetos/projeto/exportar/lm-txt";

function App() {
    return (
        <Flowbite>
            <ToastProvider>
                <Router>
                    <Routes>
                        <Route path="/login" element={<PaginaLogin />} />
                        <Route
                            path="/admin/*"
                            element={
                                <AuthContextProvider>
                                    <Routes>
                                        <Route
                                            path="/cliente/*"
                                            element={<ClienteRoutes />}
                                        />
                                        <Route
                                            path="/usuario/meu-perfil"
                                            element={<MeuPerfilPage />}
                                        />
                                        <Route
                                            path="/usuario/criar"
                                            element={<UsuarioCrudPage />}
                                        />
                                        <Route
                                            path="/usuario/:id_usuario/*"
                                            element={<UsuarioCrudPage />}
                                        />
                                        <Route
                                            path="/usuario"
                                            element={<UsuarioListPage />}
                                        />
                                        <Route
                                            path="/logout"
                                            element={<LogoutPage />}
                                        />
                                        <Route
                                            path="*"
                                            element={<ClienteListPage />}
                                        />
                                    </Routes>
                                </AuthContextProvider>
                            }
                        />
                        <Route path="*" element={<PaginaLogin />} />
                    </Routes>
                </Router>
            </ToastProvider>
        </Flowbite>
    );
}

const ProjetoRoutes = () => {
    return (
        <Routes>
            <Route path="criar" element={<ProjetoCrudPage />} />
            <Route
                path="/:id_projeto/*"
                element={
                    <Routes>
                        <Route path="parte/*" element={<ParteRoutes />} />
                        <Route path="subparte/*" element={<SubparteRoutes />} />
                        <Route path="editar" element={<ProjetoCrudPage />} />
                        <Route
                            path="preenchimento"
                            element={<ProjetoPreenchimentoPage />}
                        />
                        <Route
                            path="importar"
                            element={<ProjetoImportarPage />}
                        />
                        <Route path="erros" element={<ProjetoErrosPage />} />
                        <Route
                            path="exportar/lm-excel"
                            element={<ExportarLmExcelPage />}
                        />
                        <Route
                            path="exportar/lm-txt"
                            element={<ExportarLmTxtPage />}
                        />
                        <Route
                            path="exportar"
                            element={<ProjetoExportarPage />}
                        />
                    </Routes>
                }
            />
            <Route path="*" element={<ProjetoListPage />} />
        </Routes>
    );
};

const ParteRoutes = () => {
    return (
        <Routes>
            <Route path="criar" element={<ParteCrudPage />} />
            <Route
                path="/:id_parte/*"
                element={
                    <Routes>
                        <Route path="editar" element={<ParteCrudPage />} />
                    </Routes>
                }
            />
            <Route path="*" element={<ParteListPage />} />
        </Routes>
    );
};

const SubparteRoutes = () => {
    return (
        <Routes>
            <Route path="criar" element={<SubparteCrudPage />} />
            <Route
                path="/:id_subparte/*"
                element={
                    <Routes>
                        <Route path="editar" element={<SubparteCrudPage />} />
                        <Route path="itens" element={<SubparteItensPage />} />
                    </Routes>
                }
            />
            <Route path="*" element={<SubparteListPage />} />
        </Routes>
    );
};

const ClienteRoutes = () => {
    return (
        <Routes>
            <Route path="criar" element={<ClienteCrudPage />} />
            <Route
                path="/:id_cliente/*"
                element={
                    <Routes>
                        <Route path="editar" element={<ClienteCrudPage />} />
                        <Route path="projeto/*" element={<ProjetoRoutes />} />
                    </Routes>
                }
            />
            <Route path="*" element={<ClienteListPage />} />
        </Routes>
    );
};

export default App;
