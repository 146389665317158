import { Button, Drawer } from "flowbite-react";
import { useParams } from "react-router";
import ProjetoSubparte from "../../../../../../services/projeto_subparte";
import { CrudProvider } from "../../../../../../context/crud-context";
import React from "react";
import useCrud from "../../../../../../hooks/crud";
import Formulario from "../../../../../../layout/form/form";
import TextField from "../../../../../../layout/form/text-field";

export function BarraLateralSubparte({ show, close }) {
    const { id_cliente, id_projeto, id_subparte } = useParams();

    let source = new ProjetoSubparte(id_cliente, id_projeto, id_subparte);

    return (
        <>
            <Drawer open={show} onClose={close} position="left">
                <Drawer.Header title="Editar Subparte" />
                <Drawer.Items>
                    <span style={{fontSize: '0.8rem'}}>
                        OBS: Alterações nesta aba são realizadas
                        imediatamentes ao salvar
                    </span>
                    <CrudProvider
                        saveCallback={close}
                        param="id_subparte"
                        id={id_subparte}
                        source={source}
                    >
                        <Form />
                    </CrudProvider>
                </Drawer.Items>
            </Drawer>
        </>
    );
}

function Form() {
    const { options } = useCrud();

    return (
        <Formulario>
            <TextField autoFocus label="Nome" name="nome" required={true} />
            <TextField label="Desenho" name="desenho" required={false} />
            <TextField label="Revisão" name="revisao" required={false} />
            <TextField
                label="Sigla"
                name="sigla"
                required={false}
                disabled={options.flag_sigla_bloqueado}
            />
            <Button type="submit" color="blue">
                Salvar
            </Button>
        </Formulario>
    );
}
