import Filtros from "./filtros";
import InputBusca from "./busca";
import React from "react";

export default function BarraFerramentas({ children }) {
    return (
        <div className="flex justify-between items-center">
            <Filtros />
            <div className="flex">
                <InputBusca />
                {children}
            </div>
        </div>
    );
}
