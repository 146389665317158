import React from "react";
import BarraFerramentas from "layout/listagem/barra-ferramentas";
import BotaoListagem from "layout/listagem/botao-listagem";
import TabelaColuna from "layout/listagem/tabela-coluna";
import TabelaListagem from "layout/listagem/tabela-listagem";
import Breadcrumbs from "layout/admin/breadcrumbs";
import AdminLayout from "layout/admin-layout";
import { FaPlusCircle } from "react-icons/fa";
import { route, urls } from "../../../urls";
import { ListagemProvider } from "../../../context/listagem-context";
import { Badge } from "flowbite-react";
import ListLink from "../../../layout/listagem/list-link";
import Usuario from "../../../services/usuario";

function UsuarioListPage() {
    const titulo = "Lista de Usuários";

    return (
        <AdminLayout>
            <Breadcrumbs
                title={titulo}
                breadcrumbs={[
                    {
                        label: "Lista de Usuários",
                        url: route(urls.web.admin.usuario.listar),
                    },
                ]}
            />
            <ListagemProvider source={new Usuario()}>
                <Listagem />
            </ListagemProvider>
        </AdminLayout>
    );
}

const Listagem = () => {
    const tipoUsuarioBadge = (tipo) => {
        const tipos = {
            1: "Administrador",
            2: "Projetistas Externo",
        };

        const cores = {
            1: "blue",
            2: "failure",
        };

        return (
            <Badge className="w-48" color={cores[tipo]}>
                {tipos[tipo]}
            </Badge>
        );
    };

    return (
        <div className="p-4 bg-white">
            <BarraFerramentas>
                <BotaoListagem
                    url={route(urls.web.admin.usuario.criar)}
                    label="Novo Usuário"
                    icon={<FaPlusCircle />}
                />
            </BarraFerramentas>
            <div className="mt-5 overflow-x-auto">
                <TabelaListagem>
                    <TabelaColuna
                        label="ID"
                        size="xs"
                        name="id"
                        callback={(row) => (
                            <ListLink
                                to={route(urls.web.admin.usuario.editar, {
                                    id_usuario: row.id,
                                })}
                            >
                                {row.id}
                            </ListLink>
                        )}
                    />
                    <TabelaColuna label="Nome" name="nome" />
                    <TabelaColuna label="Email" name="email" />
                    <TabelaColuna label="Iniciais" name="iniciais" />
                    <TabelaColuna
                        label="Tipo de Usuário"
                        name="tipo_usuario"
                        callback={(row) => tipoUsuarioBadge(row.tipo_usuario)}
                    />
                </TabelaListagem>
            </div>
        </div>
    );
};

export default UsuarioListPage;
