const APP_TOKEN_KEY = "@auth-token";

export function getToken() {
    const token = localStorage.getItem(APP_TOKEN_KEY);
    if (!token) {
        return null;
    }
    return token;
}

export function setToken(token = "") {
    localStorage.setItem(APP_TOKEN_KEY, token);
}

export function clearToken() {
    localStorage.setItem(APP_TOKEN_KEY, null);
}
