import React, { createContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Spinner } from "flowbite-react";

const ListagemContext = createContext({});

export const ListagemProvider = ({ children, source }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const location = useLocation();

    const getData = () => {
        setLoading(true);
        source
            .list(new URLSearchParams(window.location.search))
            .then((data) => {
                setData(data);
            })
            .catch((error) => {
                console.error("Erro ao buscar dados da listagem", error);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getData();
    }, [source, location.pathname, location.search]);

    return (
        <ListagemContext.Provider value={{ data, loading }}>
            {!loading && !error && children}
            {error && (
                <div>
                    <div className="flex gap-2 items-center flex-col justify-center text-gray-500">
                        <span>Ocorreu um erro ao carregar os registros</span>
                        <span className="text-sm">
                            Verifique sua conexão com a internet
                        </span>
                    </div>
                </div>
            )}
            {loading && (
                <div>
                    <div
                        className="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200
                      rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
                    >
                        <div className="text-center">
                            <span className="text-sm mr-3">Carregando...</span>
                            <Spinner color="success" aria-label="Carregando" />
                        </div>

                        {[...Array(2).keys()].map((item, i) => {
                            return (
                                <div
                                    className="flex items-center justify-between pt-4"
                                    key={i}
                                >
                                    <div>
                                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
                                        <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
                                    </div>
                                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </ListagemContext.Provider>
    );
};

export default ListagemContext;
