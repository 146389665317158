export function updateQuery(navigate, query, value) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(query, value);
    navigate(`${window.location.pathname}?${searchParams.toString()}`);
}

export function refreshQuery(navigate) {
    const searchParams = new URLSearchParams(window.location.search);
    const randomNumber = Math.floor(Math.random() * 10000);
    searchParams.set("update__", "" + randomNumber);
    navigate(`${window.location.pathname}?${searchParams.toString()}`);
}

export function getQuery(query) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(query);
}
