import React, { createContext, useEffect, useState } from "react";
import { Spinner } from "flowbite-react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../hooks/toast";
import ToastErro from "../layout/toast/toast-erro";
import ToastSucesso from "../layout/toast/toast-sucesso";

const CrudContext = createContext({});

export const CrudProvider = ({
    id,
    children,
    source,
    saveCallback,
    redirBackUrl,
    defaultValues = {},
}) => {
    const addToast = useToast();
    const [options_, setOptions] = useState([]);
    const [initial, setInitial] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [isOptionsLoaded, setOptionsLoaded] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const form = useForm({
        defaultValues: defaultValues,
        shouldFocusError: true,
    });

    const getData = () => {
        setLoading(true);
        source
            .show()
            .then((data) => {
                setInitial(data);
                form.reset(data);
            })
            .catch((error) => {
                addToast(
                    <ToastErro msg="Ocorreu um erro ao obter dados do registro, verifique sua conexão com a internet" />,
                );
                setError(true);
            })
            .finally(() => {
                setLoading(false);
                setDataLoaded(true);
            });
    };

    const getOptions = () => {
        setLoading(true);
        source
            .options()
            .then((data) => {
                setOptions(data);
            })
            .catch((error) => {
                addToast(
                    <ToastErro msg="Ocorreu um erro ao obter dados do registro, verifique sua conexão com a internet" />,
                );
                setError(true);
            })
            .finally(() => {
                setOptionsLoaded(true);
            });
    };

    const saveItem = (values) => {
        setSaving(true);
        source
            .save(values)
            .then((data) => {
                setInitial(data);
                form.reset(data);
                addToast(<ToastSucesso msg="Salvo com sucesso" />);
                setSaving(false);
                if (values?._redir === "list") {
                    goBack();
                    return;
                }

                if (saveCallback) {
                    saveCallback()
                    return;
                }

                let pathname = location.pathname.replace(
                    "/criar",
                    "/" + data.id + "/editar",
                );
                navigate(pathname + location.search);
            })
            .catch(({ response }) => {
                if (response?.status === 400) {
                    setErrors(form.setError, response?.data);
                    addToast(<ToastErro msg="Corrija os campos indicados" />);
                    setSaving(false);
                    return;
                }
                addToast(
                    <ToastErro msg="Ocorreu um erro ao salvar o registro, verifique sua conexão com a internet" />,
                );
                setSaving(false);
            });
    };

    const goBack = () => {
        const urlParams = new URLSearchParams(location.search);
        let filters = decodeURIComponent(urlParams.get("_filters") ?? "");
        if (!filters) {
            filters = "";
        }

        if (redirBackUrl) {
            navigate(redirBackUrl);
            return;
        }

        let backUrl = location.pathname
            .replace("/criar", "")
            .replace(/\/\d+\/editar/, "");
        navigate(`${backUrl}${filters}`);
    };

    const destroyItem = () => {
        setSaving(true);
        source
            .delete()
            .then((data) => {
                goBack();
            })
            .catch((error) => {
                addToast(
                    <ToastErro msg="Ocorreu um erro ao salvar o registro, verifique sua conexão com a internet" />,
                );
            })
            .finally(() => {
                setSaving(false);
            });
    };

    function setErrors(setError, data, prefix = "") {
        for (let [field, messages] of Object.entries(data)) {
            field = prefix !== "" ? prefix + "[]." + field : field;

            if (
                messages === Object(messages) &&
                typeof messages[0] !== "string"
            ) {
                setErrors(setError, messages, field);
                continue;
            }

            let message = Array.isArray(messages) ? messages[0] : messages;
            setError(field, { type: "manual", message: message });
        }
    }

    useEffect(() => {
        if (source?.options) {
            getOptions();
        } else {
            setOptionsLoaded(true);
        }

        if (source?.show && id > 0) {
            getData();
        } else {
            setDataLoaded(true);
        }
    }, [id]);

    return (
        <CrudContext.Provider
            value={{
                initial,
                form: form,
                setSaving,
                saving,
                setInitial,
                loading,
                goBack,
                getData,
                options: options_,
                getOptions,
                saveItem,
                destroyItem,
            }}
        >
            {isDataLoaded && isOptionsLoaded && !error && children}
            {(!isDataLoaded || !isOptionsLoaded) && (
                <div
                    className="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200
                      rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
                >
                    <div className="text-center">
                        <span className="text-sm mr-3">Carregando...</span>
                        <Spinner color="success" aria-label="Carregando" />
                    </div>
                </div>
            )}
            {error && (
                <div className="text-center">
                    <div className="flex gap-2 items-center flex-col justify-center my-4 text-gray-500">
                        <span>Ocorreu um erro ao carregar o registro</span>
                        <span>Verifique sua conexão com a internet</span>
                        {
                            <button
                                type="button"
                                className="text-blue-500 font-bold"
                                onClick={goBack}
                            >
                                Voltar
                            </button>
                        }
                    </div>
                </div>
            )}
        </CrudContext.Provider>
    );
};

export default CrudContext;
