import { route, urls } from "../urls";
import api from "../utils/api";

export default class ProjetoImportar {
    constructor(id_cliente, id_projeto = 0) {
        this.url_params = { id_cliente: id_cliente, id_projeto: id_projeto };
    }

    async save(values) {
        const url = route(
            urls.api.admin.cliente.projeto.importar,
            this.url_params,
        );
        api.defaults.headers["Content-Type"] = "multipart/form-data";
        const { data } = await api.post(url, values);
        return data;
    }
}
