import React from "react";
import { updateQuery, getQuery } from "../../utils/query";
import useListagem from "../../hooks/listagem";
import { FaFilter } from "react-icons/fa6";
import { useNavigate } from "react-router";

const Filtros = () => {
    const { data } = useListagem();
    let filters = data?.filters || [];
    let navigate = useNavigate();

    return (
        <div className="flex items-center space-x-6 items-center">
            {filters.map((filter, index) => (
                <div
                    key={index}
                    className="text-sm flex items-center space-x-2 mr-3"
                >
                    <div className="mb-1 text-gray-500 font-medium flex items-center">
                        <FaFilter />
                        <span className="ml-2 text-sm">{filter.label}</span>
                    </div>
                    <select
                        defaultValue={getQuery(filter.query)}
                        className="border border-gray-300 rounded px-3 py-2 text-sm text-gray-500"
                        onChange={(e) => {
                            updateQuery(navigate, filter.query, e.target.value);
                        }}
                    >
                        <option label="-" value="" />
                        {filter.options.map((option, idx) => (
                            <option key={idx} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            ))}
        </div>
    );
};

export default Filtros;
