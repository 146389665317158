export const downloadFile = (
    response,
    defaultFilename = "download",
    defaultType = "application/octet-stream",
) => {
    // Extrair o tipo de conteúdo do cabeçalho Content-Type
    const contentType = response.headers["content-type"] || defaultType;

    // Criar um Blob com os dados recebidos e o tipo de conteúdo
    const blob = new Blob([response.data], { type: contentType });

    // Extrair o nome do arquivo do cabeçalho Content-Disposition
    const contentDisposition = response.headers["content-disposition"];
    let filename = defaultFilename;

    if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
        }
    }

    // Criar um link temporário para o download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // Adicionar o link ao corpo do documento
    document.body.appendChild(link);

    // Simular o clique para iniciar o download
    link.click();

    // Remover o link do documento
    document.body.removeChild(link);
};
