import { useContext } from "react";
import CrudContext from "../context/crud-context";

export default function useCrud() {
    const context = useContext(CrudContext);
    if (!context) {
        throw new Error("useListagem must be used within a ListagemProvider");
    }
    return context;
}
