import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BarraFerramentas from "layout/listagem/barra-ferramentas";
import BotaoListagem from "layout/listagem/botao-listagem";
import TabelaColuna from "layout/listagem/tabela-coluna";
import TabelaListagem from "layout/listagem/tabela-listagem";
import Breadcrumbs from "layout/admin/breadcrumbs";
import AdminLayout from "layout/admin-layout";
import { route, urls } from "../../../../../urls";
import { ListagemProvider } from "../../../../../context/listagem-context";
import { FaArrowDown, FaArrowUp, FaPlusCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import ProjetoParte from "../../../../../services/projeto_parte";
import useListagem from "../../../../../hooks/listagem";
import ListLink from "../../../../../layout/listagem/list-link";
import ProjetoLayout from "../../../../../layout/projeto-layout";
import ProjetoSubparte from "../../../../../services/projeto_subparte";
import { refreshQuery } from "../../../../../utils/query";

function ParteListPage() {
    const titulo = "Lista de Partes";
    const { id_cliente, id_projeto } = useParams();

    return (
        <AdminLayout>
            <ProjetoLayout active="partes">
                <ListagemProvider
                    source={new ProjetoParte(id_cliente, id_projeto)}
                >
                    <Breadcrumbs
                        title="Partes"
                        breadcrumbs={[
                            {
                                label: "Clientes",
                                url: route(urls.web.admin.cliente.listar),
                            },
                            {
                                label: "Projetos",
                                url: route(
                                    urls.web.admin.cliente.projeto.listar,
                                    {
                                        id_cliente: id_cliente,
                                    },
                                ),
                            },
                            {
                                label: "Partes",
                                url: "",
                            },
                        ]}
                    />
                    <Listagem />
                </ListagemProvider>
            </ProjetoLayout>
        </AdminLayout>
    );
}

const Listagem = () => {
    const { id_cliente, id_projeto } = useParams();
    const navigate = useNavigate();

    return (
        <div className="p-4 bg-white">
            <BarraFerramentas>
                <BotaoListagem
                    url={route(urls.web.admin.cliente.projeto.parte.criar, {
                        id_cliente,
                        id_projeto,
                    })}
                    label="Nova Parte"
                    icon={<FaPlusCircle />}
                />
            </BarraFerramentas>
            <div className="mt-5 overflow-x-auto">
                <TabelaListagem>
                    <TabelaColuna
                        label="Nome"
                        name="nome"
                        callback={(row) => {
                            return (
                                <ListLink
                                    to={route(
                                        urls.web.admin.cliente.projeto.parte
                                            .editar,
                                        {
                                            id_cliente,
                                            id_projeto,
                                            id_parte: row.id,
                                        },
                                    )}
                                >
                                    {row.nome}
                                </ListLink>
                            );
                        }}
                    />
                    <TabelaColuna label="Revisão" name="revisao" />
                    <TabelaColuna label="Abreviação" name="abreviacao" />
                    <TabelaColuna
                        align="C"
                        size="sm"
                        label="Ordem"
                        name="ordem"
                        callback={(row) => {
                            return (
                                <div className="flex justify-center">
                                    <button
                                        type="button"
                                        className="bg-gray-200 text-gray-700 p-2"
                                        onClick={() => {
                                            new ProjetoParte(
                                                id_cliente,
                                                id_projeto,
                                                row.id,
                                            )
                                                .alterar_ordem("-")
                                                .then(() => {
                                                    refreshQuery(navigate);
                                                });
                                        }}
                                    >
                                        <FaArrowUp />
                                    </button>
                                    <button
                                        type="button"
                                        className="border text-gray-600 p-2"
                                        onClick={() => {
                                            new ProjetoParte(
                                                id_cliente,
                                                id_projeto,
                                                row.id,
                                            )
                                                .alterar_ordem("+")
                                                .then(() => {
                                                    refreshQuery(navigate);
                                                });
                                        }}
                                    >
                                        <FaArrowDown />
                                    </button>
                                </div>
                            );
                        }}
                    />
                </TabelaListagem>
            </div>
        </div>
    );
};

export default ParteListPage;
