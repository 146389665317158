import React from "react";
import useCrud from "../../hooks/crud";
import { FaCircle } from "react-icons/fa";

export default function ErrorField({ name = "" }) {
    const { form } = useCrud();

    let erro = form.getFieldState(name)?.error?.message;
    if (!erro) {
        return <></>;
    }

    return (
        <span
            style={{ fontSize: "0.8rem" }}
            className="ml-1 font-medium text-red-500 flex items-center my-1"
        >
            <FaCircle size={6} className="mr-1" />
            {erro}
        </span>
    );
}
