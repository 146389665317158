import AdminLayout from "layout/admin-layout";
import Breadcrumbs from "layout/admin/breadcrumbs";
import React from "react";
import Panel from "../../../../../layout/form/panel";
import TextField from "../../../../../layout/form/text-field";
import { route, urls } from "../../../../../urls";
import { useParams } from "react-router";
import Formulario from "../../../../../layout/form/form";
import { CrudProvider } from "../../../../../context/crud-context";
import ProjetoParte from "../../../../../services/projeto_parte";
import useCrud from "../../../../../hooks/crud";
import { useFieldArray } from "react-hook-form";
import { Button, Select, TextInput, Table } from "flowbite-react";
import { Controller } from "react-hook-form";
import BotoesSubmit from "../../../../../layout/form/botoes-submit";
import ProjetoLayout from "../../../../../layout/projeto-layout";

export default function ParteCrudPage() {
    const { id_cliente, id_projeto, id_parte } = useParams();

    let source = new ProjetoParte(id_cliente, id_projeto, id_parte);

    return (
        <AdminLayout>
            <ProjetoLayout active="partes">
                <Breadcrumbs
                    title="Partes"
                    breadcrumbs={[
                        {
                            label: "Clientes",
                            url: route(urls.web.admin.cliente.listar),
                        },
                        {
                            label: "Projetos",
                            url: route(urls.web.admin.cliente.projeto.listar, {
                                id_cliente: id_cliente,
                            }),
                        },
                        {
                            label: "Partes",
                            url: route(
                                urls.web.admin.cliente.projeto.parte.listar,
                                {
                                    id_cliente: id_cliente,
                                    id_projeto: id_projeto,
                                },
                            ),
                        },
                        {
                            label:
                                id_parte > 0 ? "Editar Parte" : "Criar Parte",
                            url: "",
                        },
                    ]}
                />
                <div className="container mx-auto max-w-5xl">
                    <CrudProvider
                        param="id_parte"
                        id={id_parte}
                        source={source}
                    >
                        <Form />
                    </CrudProvider>
                </div>
            </ProjetoLayout>
        </AdminLayout>
    );
}

function Form() {
    const crud = useCrud();
    const { form, options } = crud;
    const control = form.control;
    const subpartesField = useFieldArray({
        control: form.control,
        name: "subpartes",
    });
    const subpartes = subpartesField.fields;
    const subpartesAdd = subpartesField.append;
    const subpartesRemove = subpartesField.remove;
    let opcoesSubpartes = options.subpartes ?? [];

    let subpartesAdicionadas = form.watch("subpartes") ?? [];
    let idsAdicionados = subpartesAdicionadas.map((x) => {
        return +x.subparte_id;
    });

    // Função para filtrar as opções já selecionadas
    const getFilteredOptions = (index) => {
        const idsExcluidos = idsAdicionados.filter((_, i) => i !== index); // Exclui o item atual
        return opcoesSubpartes.filter(
            (op) => !idsExcluidos.includes(+op.value),
        );
    };

    return (
        <Formulario>
            <Panel>
                <TextField autoFocus label="Nome" name="nome" required={true} />
                <TextField label="Revisão" name="revisao" required={false} />
                <TextField
                    label="Abreviação"
                    name="abreviacao"
                    required={false}
                />
            </Panel>
            <Panel title="Subpartes">
                <div className="flex justify-end">
                    <Button
                        type="button"
                        size="xs"
                        color="dark"
                        onClick={() =>
                            subpartesAdd({
                                id: "",
                                subparte_id: "",
                                quantidade: "",
                            })
                        }
                    >
                        Adicionar Subparte
                    </Button>
                </div>

                <Table striped hoverable size="xs">
                    <Table.Head>
                        <Table.HeadCell>Subparte</Table.HeadCell>
                        <Table.HeadCell>Quantidade</Table.HeadCell>
                        <Table.HeadCell>Remover</Table.HeadCell>
                    </Table.Head>
                    <Table.Body>
                        {subpartes.map((item, index) => (
                            <Table.Row key={item.id}>
                                <Table.Cell className="py-1 px-1 w-full">
                                    <Controller
                                        name={`subpartes[${index}].id`}
                                        control={control}
                                        render={({ field }) => (
                                            <input
                                                className="hidden"
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`subpartes[${index}].subparte_id`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select required={true} {...field}>
                                                <option value="" />
                                                {getFilteredOptions(index).map(
                                                    (item, i) => {
                                                        return (
                                                            <option
                                                                value={
                                                                    item.value
                                                                }
                                                                key={i}
                                                            >
                                                                {item.label}
                                                            </option>
                                                        );
                                                    },
                                                )}
                                            </Select>
                                        )}
                                    />
                                </Table.Cell>
                                <Table.Cell
                                    className="py-1 px-1"
                                    style={{ width: "4rem" }}
                                >
                                    <Controller
                                        name={`subpartes[${index}].quantidade`}
                                        control={control}
                                        render={({ field }) => (
                                            <TextInput
                                                required={true}
                                                {...field}
                                                type="number"
                                            />
                                        )}
                                    />
                                </Table.Cell>
                                <Table.Cell
                                    className="py-1 px-1"
                                    style={{ width: "5rem" }}
                                >
                                    <div className="flex items-center justify-center">
                                        <Button
                                            size="xs"
                                            color="failure"
                                            type="button"
                                            onClick={() => {
                                                let confirmacao =
                                                    window.confirm(
                                                        "Deseja desvincular esta subparte?",
                                                    );
                                                if (!confirmacao) {
                                                    return;
                                                }
                                                subpartesRemove(index);
                                            }}
                                        >
                                            Remover
                                        </Button>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Panel>
            <BotoesSubmit />
        </Formulario>
    );
}
