import React, { useEffect, useState } from "react";
import UsuarioLogin from "../services/usuario_login";
import { useNavigate } from "react-router";
import { route, urls } from "../urls";

export const AuthContext = React.createContext({});

export default function AuthContextProvider({ children }) {
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        new UsuarioLogin()
            .me()
            .then((user) => {
                setAuthenticated(true);
                setUser(user);
                setLoaded(true);
            })
            .catch(() => {
                setAuthenticated(false);
                setLoaded(true);
                let url =
                    route(urls.web.auth.login) +
                    "?redir=" +
                    encodeURIComponent(window.location.pathname);
                navigate(url);
            });
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user: user,
                isLoaded: isLoaded,
                isAuthenticated: isAuthenticated,
            }}
        >
            {isLoaded && isAuthenticated && children}
            {isLoaded && !isAuthenticated && <></>}
        </AuthContext.Provider>
    );
}
