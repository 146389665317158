import AdminLayout from "../../../../../../layout/admin-layout";
import ProjetoLayout from "../../../../../../layout/projeto-layout";
import { route, urls } from "../../../../../../urls";
import Breadcrumbs from "../../../../../../layout/admin/breadcrumbs";
import React, { useState } from "react";
import { useParams } from "react-router";
import Panel from "../../../../../../layout/form/panel";
import { Button } from "flowbite-react";
import ProjetoExportar from "../../../../../../services/projeto_exportar";
import { downloadFile } from "../../../../../../utils/file";

export default function ExportarLmExcelPage() {
    const { id_cliente, id_projeto } = useParams();
    const [loading, setLoading] = useState(false);

    const exportar = () => {
        setLoading(true);
        new ProjetoExportar(id_cliente, id_projeto)
            .lmExcel()
            .then((response) => {
                downloadFile(response);
            })
            .catch(() => {
                alert("Ocorreu um erro ao exportar a lista de materiais");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <AdminLayout>
            <ProjetoLayout active="exportar">
                <Breadcrumbs
                    title="Exportar Projeto"
                    breadcrumbs={[
                        {
                            label: "Clientes",
                            url: route(urls.web.admin.cliente.listar),
                        },
                        {
                            label: "Projetos",
                            url: route(urls.web.admin.cliente.projeto.listar, {
                                id_cliente,
                            }),
                        },
                        {
                            label: "Projeto #" + id_projeto,
                            url: route(urls.web.admin.cliente.projeto.editar, {
                                id_cliente,
                                id_projeto,
                            }),
                        },
                        {
                            label: "Exportar",
                            url: "",
                        },
                    ]}
                />
                <div className="mx-5 mt-3">
                    <Panel title="Exportar">
                        Não há opções para serem preenchidas
                        <div className="pt-4">
                            <Button
                                color="blue"
                                onClick={exportar}
                                isProcessing={loading}
                            >
                                Exportar lista de materiais
                            </Button>
                        </div>
                    </Panel>
                </div>
            </ProjetoLayout>
        </AdminLayout>
    );
}
