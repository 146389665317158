import React, { useEffect, useState } from "react";
import { Button, Label, Spinner, TextInput } from "flowbite-react";
import { HiEnvelope } from "react-icons/hi2";
import { HiKey } from "react-icons/hi";
import { settings } from "../../settings";
import { useForm } from "react-hook-form";
import UsuarioLogin from "../../services/usuario_login";
import { setToken } from "../../utils/token-auth";
import { route, urls } from "../../urls";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

function PaginaLogin() {
    const { handleSubmit, register } = useForm();
    const [loading, setLoading] = useState(false);
    const [checkLogin, setCheckLogin] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    const onSubmit = (values) => {
        setLoading(true);
        new UsuarioLogin()
            .login(values)
            .then((data) => {
                setToken(data.token);
                window.location.href = settings.URL_INICIAL;
            })
            .catch(() => {
                setError("Login ou senha inválidos");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        new UsuarioLogin()
            .me()
            .then((user) => {
                let redir = new URLSearchParams(location.search).get("redir");
                if (!redir) {
                    redir = route(urls.web.admin.cliente.listar);
                } else {
                    redir = decodeURIComponent(redir);
                }
                navigate(redir);
            })
            .catch(() => {
                console.log("Não logado");
            })
            .finally(() => {
                setCheckLogin(true);
            });
    }, []);

    if (!checkLogin) {
        return <></>;
    }

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-900">
            <div className="w-full max-w-md p-8 space-y-8 bg-white rounded shadow">
                <div className="flex justify-center items-center space-x-3 mr-4">
                    <img src={settings.LOGO_SISTEMA_2} alt="logo" width={60} />
                    <div className="flex flex-col">
                        <span className="text-lg font-bold text-gray-900 pt-4">
                            {settings.TITULO_SISTEMA_DISPLAY}
                        </span>
                        <span>Login</span>
                    </div>
                </div>
                <form
                    className="mt-8 space-y-6"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="rounded-md shadow-sm">
                        <div>
                            <Label htmlFor="email" className="text-gray-500">
                                Email
                            </Label>
                            <TextInput
                                {...register("email")}
                                autoFocus={true}
                                id="email"
                                name="email"
                                type="email"
                                required
                                color={error ? "failure" : undefined}
                                helperText={
                                    error ? (
                                        <>
                                            <span className="font-medium">
                                                {error}
                                            </span>
                                        </>
                                    ) : (
                                        ""
                                    )
                                }
                                icon={HiEnvelope}
                                placeholder="Email"
                            />
                        </div>
                        <div className="mt-4">
                            <Label htmlFor="senha" className="text-gray-500">
                                Senha
                            </Label>
                            <TextInput
                                id="password"
                                type="password"
                                {...register("password")}
                                name="password"
                                color={error ? "failure" : undefined}
                                icon={HiKey}
                                required
                                placeholder="Senha"
                            />
                        </div>
                    </div>
                    <div>
                        {loading && (
                            <Button
                                color="blue"
                                fullSized={true}
                                type="button"
                                disabled
                            >
                                <Spinner
                                    aria-label="carregando"
                                    size="sm"
                                    color="failure"
                                />
                                <span className="pl-3">Carregando...</span>
                            </Button>
                        )}
                        {!loading && (
                            <Button color="blue" fullSized={true} type="submit">
                                Login
                            </Button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PaginaLogin;
