import React from "react";
import { Table, Checkbox } from "flowbite-react";

export default function TabelaColunaCheckbox({ isHeader, isSelected }) {
    if (isHeader) {
        return (
            <Table.HeadCell className="py-1" style={{ width: "2rem" }}>
                <Checkbox />
            </Table.HeadCell>
        );
    }

    return (
        <Table.Cell className="py-1">
            <Checkbox checked={isSelected} readOnly />
        </Table.Cell>
    );
}
