import AdminLayout from "layout/admin-layout";
import Breadcrumbs from "layout/admin/breadcrumbs";
import React from "react";
import { useParams } from "react-router";
import Cliente from "../../../services/cliente";
import { CrudProvider } from "../../../context/crud-context";
import Formulario from "../../../layout/form/form";
import Panel from "../../../layout/form/panel";
import TextField from "../../../layout/form/text-field";
import BotoesSubmit from "../../../layout/form/botoes-submit";
import { route, urls } from "../../../urls";
import FileField from "../../../layout/form/file-field";
import DebugForm from "../../../layout/form/debug-form";

export default function ClienteCrudPage() {
    const { id_cliente } = useParams();

    let source = new Cliente(id_cliente);

    return (
        <AdminLayout>
            <Breadcrumbs
                title="Clientes"
                breadcrumbs={[
                    {
                        label: "Clientes",
                        url: route(urls.web.admin.cliente.listar),
                    },
                    {
                        label:
                            id_cliente > 0 ? "Editar Cliente" : "Criar Cliente",
                        url: "",
                    },
                ]}
            />
            <div className="container mx-auto max-w-5xl">
                <CrudProvider
                    param="id_cliente"
                    id={id_cliente}
                    source={source}
                >
                    <Form />
                </CrudProvider>
            </div>
        </AdminLayout>
    );
}

function Form() {
    return (
        <Formulario>
            <Panel>
                <TextField autoFocus label="Nome" name="nome" required={true} />
            </Panel>
            <Panel title="Logotipo">
                <FileField name="logotipo" required={false} />
            </Panel>
            <Panel title="Logotipo - Secundário">
                <FileField name="logotipo2" required={false} />
            </Panel>
            <BotoesSubmit />
        </Formulario>
    );
}
