import React from "react";
import { Link } from "react-router-dom";
import BarraFerramentas from "layout/listagem/barra-ferramentas";
import BotaoListagem from "layout/listagem/botao-listagem";
import TabelaColuna from "layout/listagem/tabela-coluna";
import TabelaListagem from "layout/listagem/tabela-listagem";
import Breadcrumbs from "layout/admin/breadcrumbs";
import AdminLayout from "layout/admin-layout";
import { FaPlusCircle } from "react-icons/fa";
import { route, urls } from "../../../urls";
import { ListagemProvider } from "../../../context/listagem-context";
import Cliente from "../../../services/cliente";
import { Button } from "flowbite-react";
import ListLink from "../../../layout/listagem/list-link";
import { useAuth } from "../../../hooks/auth";

function ClienteListPage() {
    const titulo = "Lista de Clientes";

    return (
        <AdminLayout>
            <Breadcrumbs
                title={titulo}
                breadcrumbs={[
                    {
                        label: "Lista de Clientes",
                        url: route(urls.web.admin.cliente.listar),
                    },
                ]}
            />
            <ListagemProvider source={new Cliente()}>
                <Listagem />
            </ListagemProvider>
        </AdminLayout>
    );
}

const Listagem = () => {
    const { user } = useAuth();

    return (
        <div className="p-4 bg-white">
            <BarraFerramentas>
                <BotaoListagem
                    hidden={+user.tipo_usuario !== 1}
                    url={route(urls.web.admin.cliente.criar)}
                    label="Novo Cliente"
                    icon={<FaPlusCircle />}
                />
            </BarraFerramentas>
            <div className="mt-5 overflow-x-auto">
                <TabelaListagem>
                    <TabelaColuna
                        label="ID"
                        size="xs"
                        name="id"
                        callback={(row) => {
                            return (
                                <ListLink
                                    to={route(urls.web.admin.cliente.editar, {
                                        id_cliente: row.id,
                                    })}
                                >
                                    {row.id}
                                </ListLink>
                            );
                        }}
                    />
                    <TabelaColuna label="Nome" name="nome" />
                    <TabelaColuna
                        label="Projetos"
                        name="projetos"
                        callback={(row) => (
                            <Link
                                to={route(
                                    urls.web.admin.cliente.projeto.listar,
                                    {
                                        id_cliente: row.id,
                                    },
                                )}
                            >
                                <Button color="blue" size="xs">
                                    Projetos
                                </Button>
                            </Link>
                        )}
                    />
                </TabelaListagem>
            </div>
        </div>
    );
};

export default ClienteListPage;
