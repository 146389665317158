import React from "react";
import useCrud from "../../hooks/crud";

export default function Formulario({ children }) {
    const { form, saveItem } = useCrud();

    return (
        <div className="mt-4 mx-auto">
            <form onSubmit={form.handleSubmit(saveItem)}>{children}</form>
        </div>
    );
}
