import useCrud from "../../hooks/crud";

export default function Panel({ title = "Opções Gerais", disabled, children }) {
    const { saving } = useCrud();

    return (
        <fieldset
            className="rounded shadow bg-white flex flex-col mb-3"
            disabled={saving}
        >
            <div className="font-medium border-b text-gray-700 p-3">
                <span className="ml-2">{title}</span>
            </div>
            <div className="p-5">{children}</div>
        </fieldset>
    );
}
