import { Link } from "react-router-dom";
import { Button } from "flowbite-react";
import Icon from "../icon";
import React from "react";

export default function BotaoListagem({
    url,
    hidden,
    label,
    icon,
    color = "blue",
}) {
    if (hidden) {
        return <></>;
    }

    return (
        <Link to={url}>
            <Button color={color} type="button">
                <div className="flex items-center">
                    <Icon className="mr-2">{icon}</Icon> <span>{label}</span>
                </div>
            </Button>
        </Link>
    );
}
