import { Label, Select, Tooltip } from "flowbite-react";
import useCrud from "../../hooks/crud";
import { FaInfoCircle } from "react-icons/fa";
import ErrorField from "./error-field";
import React from "react";

export default function SelectField({
    required = false,
    help,
    label = "",
    name,
    options = [],
    size,
    ...props
}) {
    let id = "f-" + name;

    const { form } = useCrud();

    return (
        <div className="mb-4">
            <div className="flex mb-1 text-sm">
                <Label htmlFor={id} value={label} className="text-gray-600" />
                {required && (
                    <span className="text-red-500 font-bold ml-1">*</span>
                )}
                {help && (
                    <Tooltip content={help}>
                        <FaInfoCircle className="text-blue-600 ml-1 text-sm" />
                    </Tooltip>
                )}
            </div>
            <Select
                {...props}
                {...form.register(name)}
                id={id}
                name={name}
                required={required}
                size={size}
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>
            <ErrorField name={name} />
        </div>
    );
}
