import AdminLayout from "layout/admin-layout";
import Breadcrumbs from "layout/admin/breadcrumbs";
import React from "react";
import Usuario from "../../../services/usuario";
import { CrudProvider } from "../../../context/crud-context";
import Formulario from "../../../layout/form/form";
import Panel from "../../../layout/form/panel";
import TextField from "../../../layout/form/text-field";
import SelectField from "../../../layout/form/select-field";
import useCrud from "../../../hooks/crud";
import SwitchField from "../../../layout/form/switch-field";
import { useAuth } from "../../../hooks/auth";
import { Button } from "flowbite-react";

export default function MeuPerfilPage() {
    let { user } = useAuth();
    let source = new Usuario(user.id);

    return (
        <AdminLayout>
            <Breadcrumbs title="Meu Perfil" breadcrumbs={[]} />
            <div className="container mx-auto max-w-5xl">
                <CrudProvider id={user.id} source={source}>
                    <Form id_usuario={user.id} />
                </CrudProvider>
            </div>
        </AdminLayout>
    );
}

function Form({ id_usuario }) {
    const { form, options } = useCrud();
    const { watch } = form;

    return (
        <Formulario>
            <Panel>
                <TextField autoFocus label="Nome" name="nome" required={true} />
                <TextField label="Email" name="email" required={true} />
                <TextField label="Iniciais" name="iniciais" required={true} />
                {id_usuario > 0 && (
                    <>
                        <SwitchField
                            label="Alterar senha?"
                            name="alterar_senha"
                        />
                        {watch("alterar_senha") === true && (
                            <>
                                <TextField
                                    autocomplete="new-password"
                                    label="Senha"
                                    name="password"
                                    required={true}
                                    type="password"
                                />
                                <TextField
                                    autocomplete="new-password"
                                    label="Senha (Repetir)"
                                    name="password_repeat"
                                    required={true}
                                    type="password"
                                />
                            </>
                        )}
                    </>
                )}

                {!id_usuario && (
                    <>
                        <TextField
                            autocomplete="new-password"
                            label="Senha"
                            name="password"
                            required={true}
                            type="password"
                        />
                        <TextField
                            autocomplete="new-password"
                            label="Senha (Repetir)"
                            name="password_repeat"
                            required={true}
                            type="password"
                        />
                    </>
                )}
            </Panel>
            <Button type="submit" color="blue" className="sm:w-full md:w-auto">
                Salvar
            </Button>
        </Formulario>
    );
}
