import { route, urls } from "../urls";
import api from "../utils/api";

export default class ProjetoExportar {
    constructor(id_cliente, id_projeto = 0) {
        this.url_params = { id_cliente: id_cliente, id_projeto: id_projeto };
    }

    async lmExcel() {
        const url = route(
            urls.api.admin.cliente.projeto.exportar.lm_excel,
            this.url_params,
        );
        return await api.get(url, {
            responseType: "blob",
        });
    }

    async lmTxt() {
        const url = route(
            urls.api.admin.cliente.projeto.exportar.lm_txt,
            this.url_params,
        );
        return await api.get(url, {
            responseType: "blob",
        });
    }
}
