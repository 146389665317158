import { Link, useParams } from "react-router-dom";
import { route, urls } from "../urls";
import { Sidebar } from "flowbite-react";
import React from "react";
import {
    FaCogs,
    FaFileExcel,
    FaFileImport,
    FaFirstOrder,
    FaProjectDiagram,
} from "react-icons/fa";
import { FaMagnifyingGlass, FaTowerCell } from "react-icons/fa6";

export default function ProjetoLayout({
    children,
    active = "",
    direction = 'flex-row',
    collapse = false,
}) {
    const { id_cliente, id_projeto } = useParams();

    if (!id_projeto) {
        return <>{children}</>;
    }

    return (
        <>
            <div className={"flex h-full " + direction}>
                <Sidebar
                    collapsed={collapse}
                    style={{ height: "auto" }}
                    theme={{
                        item: {
                            active: "bg-gray-200 text-gray-800 font-medium",
                            icon: {
                                active: "text-gray-800 dark:text-gray-100",
                            },
                        },
                        root: {
                            inner: "h-full overflow-x-hidden rounded bg-gray-200 px-3 py-4 dark:bg-gray-800",
                        },
                    }}
                >
                    <Sidebar.Items>
                        <Sidebar.ItemGroup>
                            <Sidebar.Item
                                active={active === "dados-gerais"}
                                icon={FaTowerCell}
                                as={Link}
                                to={route(
                                    urls.web.admin.cliente.projeto.editar,
                                    {
                                        id_cliente: id_cliente,
                                        id_projeto: id_projeto,
                                    },
                                )}
                            >
                                Dados Gerais
                            </Sidebar.Item>
                            <Sidebar.Item
                                active={active === "auto-preenchimento"}
                                icon={FaCogs}
                                as={Link}
                                to={route(
                                    urls.web.admin.cliente.projeto
                                        .preenchimento,
                                    {
                                        id_cliente: id_cliente,
                                        id_projeto: id_projeto,
                                    },
                                )}
                            >
                                Auto-preenchimento
                            </Sidebar.Item>
                            <Sidebar.Item
                                active={active === "subpartes"}
                                icon={FaFirstOrder}
                                as={Link}
                                to={route(
                                    urls.web.admin.cliente.projeto.subparte
                                        .listar,
                                    {
                                        id_cliente: id_cliente,
                                        id_projeto: id_projeto,
                                    },
                                )}
                            >
                                Subpartes
                            </Sidebar.Item>
                            <Sidebar.Item
                                active={active === "partes"}
                                icon={FaProjectDiagram}
                                as={Link}
                                to={route(
                                    urls.web.admin.cliente.projeto.parte.listar,
                                    {
                                        id_cliente: id_cliente,
                                        id_projeto: id_projeto,
                                    },
                                )}
                            >
                                Partes
                            </Sidebar.Item>
                            <Sidebar.Item
                                active={active === "importar"}
                                icon={FaFileImport}
                                as={Link}
                                to={route(
                                    urls.web.admin.cliente.projeto.importar,
                                    {
                                        id_cliente: id_cliente,
                                        id_projeto: id_projeto,
                                    },
                                )}
                            >
                                Importar
                            </Sidebar.Item>
                            <Sidebar.Item
                                active={active === "exportar"}
                                icon={FaFileExcel}
                                as={Link}
                                to={route(
                                    urls.web.admin.cliente.projeto.exportar
                                        .listar,
                                    {
                                        id_cliente: id_cliente,
                                        id_projeto: id_projeto,
                                    },
                                )}
                            >
                                Exportação
                            </Sidebar.Item>

                            <Sidebar.Item
                                active={active === "erros"}
                                icon={FaMagnifyingGlass}
                                as={Link}
                                to={route(
                                    urls.web.admin.cliente.projeto.erros,
                                    {
                                        id_cliente: id_cliente,
                                        id_projeto: id_projeto,
                                    },
                                )}
                            >
                                Log Erros
                            </Sidebar.Item>
                        </Sidebar.ItemGroup>
                    </Sidebar.Items>
                </Sidebar>
                <div className="flex-1">{children}</div>
            </div>
        </>
    );
}
