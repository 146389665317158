import React from "react";
import { proximaCelula } from "pages/admin/cliente/projetos/subparte/components/utils";

export default function InputLm({
    children,
    onCtrolQ,
    minusculo = false,
    ...props
}) {
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            proximaCelula(event);
        }

        if (event.ctrlKey && event.key === "q") {
            event.preventDefault();
            if (onCtrolQ) {
                onCtrolQ();
            }
        }
    };

    const handleInput = (event) => {
        if (minusculo) {
            return;
        }
        event.target.value = event.target.value.toUpperCase();
    };

    return (
        <input onInput={handleInput} onKeyDown={handleKeyDown} {...props}>
            {children}
        </input>
    );
}
