import { useEffect } from "react";
import { clearToken } from "../../utils/token-auth";

export default function LogoutPage() {
    useEffect(() => {
        clearToken();
        window.location.href = "/login";
    }, []);
    return <></>;
}
