import { route, urls } from "../urls";
import api from "../utils/api";

export default class Cliente {
    constructor(id_cliente = 0) {
        this.id_cliente = id_cliente || 0;
        this.url_params = { id_cliente: id_cliente };
    }

    async list(parameters) {
        const url = route(urls.api.admin.cliente.index, this.url_params);
        const { data } = await api.get(url, { params: parameters });
        return data;
    }

    async show() {
        const url = route(urls.api.admin.cliente.edit, this.url_params);
        const { data } = await api.get(url);
        return data;
    }

    async update(values) {
        const url = route(urls.api.admin.cliente.edit, this.url_params);
        const { data } = await api.patch(url, values);
        return data;
    }

    async save(values) {
        if (this.id_cliente > 0) {
            return this.update(values);
        }
        return this.create(values);
    }

    async create(values) {
        const url = route(urls.api.admin.cliente.index, this.url_params);
        const { data } = await api.post(url, values);
        return data;
    }

    async delete() {
        const url = route(urls.api.admin.cliente.edit, this.url_params);
        const { data } = await api.delete(url);
        return data;
    }
}
