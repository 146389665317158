import { Controller } from "react-hook-form";
import React from "react";
import { TooltipErro } from "pages/admin/cliente/projetos/subparte/components/utils";
import InputLm from "pages/admin/cliente/projetos/subparte/components/input-lm";

export default function TabelaFerragens({
    fieldFerragens,
    campoRevisao,
    form,
}) {
    const { control, watch } = form;

    const deletarLinha = (e, index) => {
        e.preventDefault();

        const fieldset = e.target.closest("fieldset");
        if (fieldset && fieldset.disabled) {
            return;
        }

        let confirmacao = window.confirm("Deseja deletar a linha selecionada?");
        if (!confirmacao) {
            return;
        }

        if (fieldFerragens.fields.length > 1) {
            fieldFerragens.remove(index);
        } else {
            alert("Não é possível remover a última linha");
        }
    };

    return (
        <table className="table-fixed">
            <thead className="header-sticky">
                <tr>
                    <th
                        colSpan={1}
                        className="bg-blue-600 text-white border border-gray-900 font-medium"
                    >
                        FERRAGENS
                    </th>
                    <th colSpan={2} />
                </tr>
                <tr>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Descrição
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Quantidade
                    </th>
                    <th className="bg-blue-200 border border-gray-900 font-medium">
                        Revisão
                    </th>
                </tr>
            </thead>
            <tbody>
                {fieldFerragens.fields.map((ferragem, index) => {
                    return (
                        <tr
                            key={ferragem.id}
                            className={ferragem.flag_erro ? "erro " : ""}
                        >
                            <td className="flex flex-row">
                                <TooltipErro item={ferragem} />
                                <Controller
                                    name={`ferragens.${index}.qualidade`}
                                    control={control}
                                    defaultValue={ferragem.qualidade}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            onBlur={(e) => {
                                                if (
                                                    index ===
                                                        fieldFerragens.fields
                                                            .length -
                                                            1 &&
                                                    e.target.value !== ""
                                                ) {
                                                    fieldFerragens.append(
                                                        {},
                                                        { shouldFocus: false },
                                                    );
                                                }
                                            }}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`ferragens.${index}.quantidade`}
                                    control={control}
                                    defaultValue={ferragem.quantidade}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            required={
                                                !!watch(
                                                    `ferragens.${index}.qualidade`,
                                                )
                                            }
                                            min={
                                                !!watch(
                                                    `ferragens.${index}.qualidade`,
                                                )
                                                    ? 1
                                                    : ""
                                            }
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            type="number"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                            <td>
                                <Controller
                                    name={`ferragens.${index}.rev`}
                                    control={control}
                                    defaultValue={ferragem.rev}
                                    render={({ field }) => (
                                        <InputLm
                                            {...field}
                                            onContextMenu={(e) =>
                                                deletarLinha(e, index)
                                            }
                                            disabled={!campoRevisao}
                                            type="text"
                                            className="text-center w-full input-tabela-sm"
                                        />
                                    )}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
