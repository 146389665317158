import AdminLayout from "layout/admin-layout";
import Breadcrumbs from "layout/admin/breadcrumbs";
import React, { useEffect } from "react";
import Panel from "../../../../../layout/form/panel";
import TextField from "../../../../../layout/form/text-field";
import { route, urls } from "../../../../../urls";
import { useParams } from "react-router";
import Formulario from "../../../../../layout/form/form";
import BotoesSubmit from "../../../../../layout/form/botoes-submit";
import { CrudProvider } from "../../../../../context/crud-context";
import Projeto from "../../../../../services/projeto";
import DateField from "../../../../../layout/form/date-field";
import ProjetoLayout from "../../../../../layout/projeto-layout";
import useCrud from "../../../../../hooks/crud";
import { Controller, useFieldArray } from "react-hook-form";
import { Table, TextInput } from "flowbite-react";
import { range } from "lodash/util";
import SwitchField from "../../../../../layout/form/switch-field";

export default function ProjetoCrudPage() {
    const { id_cliente, id_projeto } = useParams();

    let source = new Projeto(id_cliente, id_projeto);

    let redirBackUrl = "";
    if (id_projeto > 0) {
        redirBackUrl = route(urls.web.admin.cliente.projeto.subparte.listar, {
            id_cliente: id_cliente,
            id_projeto: id_projeto,
        });
    }

    return (
        <AdminLayout>
            <ProjetoLayout active="dados-gerais">
                <Breadcrumbs
                    title="Projetos"
                    breadcrumbs={[
                        {
                            label: "Clientes",
                            url: route(urls.web.admin.cliente.listar, {
                                id_cliente: id_cliente,
                            }),
                        },
                        {
                            label: "Projetos",
                            url: route(urls.web.admin.cliente.projeto.listar, {
                                id_cliente: id_cliente,
                            }),
                        },
                        {
                            label:
                                id_projeto > 0
                                    ? "Editar Projeto"
                                    : "Criar Projeto",
                            url: "",
                        },
                    ]}
                />
                <div className="container mx-auto max-w-5xl">
                    <CrudProvider
                        id={id_projeto}
                        source={source}
                        redirBackUrl={redirBackUrl}
                    >
                        <FormDadosGeraisProjeto />
                    </CrudProvider>
                </div>
            </ProjetoLayout>
        </AdminLayout>
    );
}

export function FormDadosGeraisProjeto() {
    const { initial, form } = useCrud();

    const fieldEtapas = useFieldArray({
        control: form.control,
        name: "etapas",
    });

    useEffect(() => {
        if ((initial?.etapas ?? []).length > 0) {
            return;
        }
        range(0, 5).forEach((item, i) => {
            fieldEtapas.append(
                {
                    rev: "",
                    data: "",
                    elaboracao: "",
                    verificacao: "",
                    flag_sigla_bloqueado: false,
                    descricao: "",
                },
                { shouldFocus: false },
            );
        });
    }, []);

    return (
        <Formulario>
            <Panel>
                <TextField autoFocus label="OS" name="os" required={true} />
                <div className="md:flex md:space-x-4">
                    <div className="flex-1">
                        <TextField
                            label="Estrutura"
                            name="estrutura"
                            required={false}
                        />
                    </div>
                    <div className="flex-1">
                        <TextField
                            label="Revisão"
                            name="revisao"
                            required={false}
                        />
                    </div>
                </div>

                <div className="md:flex md:space-x-4">
                    <div className="flex-1">
                        <TextField label="Obra" name="obra" required={false} />
                    </div>
                </div>
                <div className="md:flex md:space-x-4">
                    <div className="flex-1">
                        <TextField
                            label="Número Projeto"
                            name="numero_projeto"
                            required={false}
                        />
                    </div>
                    <div className="flex-2">
                        <TextField
                            label="Sigla"
                            name="sigla"
                            required={false}
                        />
                    </div>
                    <div className="flex-1 flex items-center">
                        <SwitchField
                            label="Bloquear sigla na subparte?"
                            name="flag_sigla_bloqueado"
                        />
                    </div>
                </div>
                <DateField label="Data" name="data" required={false} />
            </Panel>
            <Panel title="Capa da lista do Cliente">
                <div className="md:flex md:space-x-4">
                    <div className="flex-1">
                        <TextField name="elaboracao" label="Elaboração" />
                    </div>
                    <div className="flex-1">
                        <TextField name="aprovacao" label="Aprovação" />
                    </div>
                </div>
                <div className="md:flex md:space-x-4">
                    <div className="flex-1">
                        <TextField name="escalas" label="Escalas" />
                    </div>
                    <div className="flex-1">
                        <TextField
                            name="numero_cliente"
                            label="Número do cliente"
                        />
                    </div>
                </div>
                <TextField name="verificacao" label="Verificação" />

                <hr className="py-2" />

                <Table>
                    <Table.Head>
                        <Table.HeadCell>#</Table.HeadCell>
                        <Table.HeadCell>REV</Table.HeadCell>
                        <Table.HeadCell>Data</Table.HeadCell>
                        <Table.HeadCell>Descrição</Table.HeadCell>
                        <Table.HeadCell>Verificação</Table.HeadCell>
                        <Table.HeadCell>Aprovação</Table.HeadCell>
                    </Table.Head>
                    <Table.Body>
                        {fieldEtapas.fields.map((item, index) => {
                            return (
                                <Table.Row key={item.id}>
                                    <Table.Cell className="p-1">
                                        {"#" + (index + 1)}
                                    </Table.Cell>
                                    <Table.Cell className="p-1">
                                        <Controller
                                            name={`etapas.${index}.rev`}
                                            control={form.control}
                                            defaultValue={item.rev}
                                            render={({ field }) => (
                                                <TextInput {...field} />
                                            )}
                                        />
                                    </Table.Cell>
                                    <Table.Cell className="p-1">
                                        <Controller
                                            name={`etapas.${index}.data`}
                                            control={form.control}
                                            defaultValue={item.data}
                                            render={({ field }) => (
                                                <TextInput {...field} />
                                            )}
                                        />
                                    </Table.Cell>
                                    <Table.Cell className="p-1">
                                        <Controller
                                            name={`etapas.${index}.descricao`}
                                            control={form.control}
                                            defaultValue={item.descricao}
                                            render={({ field }) => (
                                                <TextInput {...field} />
                                            )}
                                        />
                                    </Table.Cell>
                                    <Table.Cell className="p-1">
                                        <Controller
                                            name={`etapas.${index}.verificacao`}
                                            control={form.control}
                                            defaultValue={item.verificacao}
                                            render={({ field }) => (
                                                <TextInput {...field} />
                                            )}
                                        />
                                    </Table.Cell>
                                    <Table.Cell className="p-1">
                                        <Controller
                                            name={`etapas.${index}.aprovacao`}
                                            control={form.control}
                                            defaultValue={item.aprovacao}
                                            render={({ field }) => (
                                                <TextInput {...field} />
                                            )}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </Panel>
            <BotoesSubmit />
        </Formulario>
    );
}
