import { Link, useLocation } from "react-router-dom";

export default function ListLink({ to, children, ...props }) {
    const location = useLocation();
    const filters = encodeURIComponent(location.search);
    const newUrl = `${to}?_filters=${filters}`;

    return (
        <Link className="text-blue-500 font-bold" to={newUrl} {...props}>
            {children}
        </Link>
    );
}
