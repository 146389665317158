import ListagemContext from "../context/listagem-context";
import { useContext } from "react";

export default function useListagem() {
    const context = useContext(ListagemContext);
    if (!context) {
        throw new Error("useListagem must be used within a ListagemProvider");
    }
    return context;
}
