import React, { useState } from "react";
import { Table } from "flowbite-react";
import TabelaColunaCheckbox from "./tabela-coluna-checkbox";
import { getQuery, updateQuery } from "../../utils/query";
import Paginacao from "./paginacao";
import useListagem from "../../hooks/listagem";
import { useNavigate } from "react-router";

const TabelaListagem = ({ children, checkBox = true }) => {
    const { data } = useListagem();
    let results = data?.results || [];
    let navigate = useNavigate();
    let orderable = data?.orderable || [];

    let queryOrdering = getQuery("ordering") ?? "";
    let direction = queryOrdering.startsWith("-") ? "-" : "";
    queryOrdering = queryOrdering.replace("-", "");
    const [ordering, setOrdering] = useState({
        column: queryOrdering,
        direction: direction,
    });
    const [selectedRows, setSelectedRows] = useState([]);

    const handleRowClick = (id) => {
        setSelectedRows((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((rowId) => rowId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };

    const handleHeaderClick = (column) => {
        if (!orderable.includes(column)) return;
        let newOrdering = { column: column, direction: "" };
        if (ordering.column === column) {
            newOrdering = {
                column: column,
                direction: ordering.direction === "" ? "-" : "",
            };
        }
        setOrdering(newOrdering);
        updateQuery(navigate, "ordering", newOrdering.direction + column);
    };

    const handleRowDoubleClick = (id) => {
        const row = document.getElementById(`row-${id}`);
        const firstLink = row.querySelector("a");
        if (firstLink) {
            firstLink.click();
        }
    };

    return (
        <>
            <Table hovered="true" striped>
                <Table.Head className="border-b border-t">
                    {checkBox && <TabelaColunaCheckbox isHeader={true} />}
                    {React.Children.map(children, (child) => {
                        const isOrderable = orderable.includes(
                            child.props.name,
                        );
                        return React.cloneElement(child, {
                            isHeader: true,
                            onClick: () => handleHeaderClick(child.props.name),
                            isOrderable,
                            ordering:
                                ordering.column === child.props.name
                                    ? ordering.direction
                                    : null,
                        });
                    })}
                </Table.Head>
                <Table.Body className="divide-y">
                    {results.map((item) => (
                        <Table.Row
                            key={item.id}
                            id={`row-${item.id}`}
                            onClick={() => handleRowClick(item.id)}
                            onDoubleClick={() => handleRowDoubleClick(item.id)}
                            className={
                                selectedRows.includes(item.id)
                                    ? " bg-gray-300 text-gray-800"
                                    : ""
                            }
                        >
                            {checkBox && (
                                <TabelaColunaCheckbox
                                    isSelected={selectedRows.includes(item.id)}
                                />
                            )}
                            {React.Children.map(children, (child) =>
                                React.cloneElement(child, { data: item }),
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Paginacao />
        </>
    );
};

export default TabelaListagem;
