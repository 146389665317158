import AdminLayout from "layout/admin-layout";
import Breadcrumbs from "layout/admin/breadcrumbs";
import React from "react";
import { useParams } from "react-router";
import Usuario from "../../../services/usuario";
import { CrudProvider } from "../../../context/crud-context";
import Formulario from "../../../layout/form/form";
import Panel from "../../../layout/form/panel";
import TextField from "../../../layout/form/text-field";
import SelectField from "../../../layout/form/select-field";
import BotoesSubmit from "../../../layout/form/botoes-submit";
import { route, urls } from "../../../urls";
import useCrud from "../../../hooks/crud";
import SwitchField from "../../../layout/form/switch-field";
import MultiSelectField from "../../../layout/form/multi-select-field";

export default function UsuarioCrudPage() {
    const { id_usuario } = useParams();

    let source = new Usuario(id_usuario);

    return (
        <AdminLayout>
            <Breadcrumbs
                title="Usuários"
                breadcrumbs={[
                    {
                        label: "Usuários",
                        url: route(urls.web.admin.usuario.listar),
                    },
                    {
                        label:
                            id_usuario > 0 ? "Editar Usuário" : "Criar Usuário",
                        url: "",
                    },
                ]}
            />
            <div className="container mx-auto max-w-5xl">
                <CrudProvider
                    param="id_usuario"
                    id={id_usuario}
                    source={source}
                >
                    <Form />
                </CrudProvider>
            </div>
        </AdminLayout>
    );
}

function Form() {
    const { id_usuario } = useParams();
    const { form, options } = useCrud();
    const { watch } = form;

    let tipoUsuario = watch("tipo_usuario");

    return (
        <Formulario>
            <Panel>
                <TextField autoFocus label="Nome" name="nome" required={true} />
                <TextField label="Email" name="email" required={true} />
                <TextField label="Iniciais" name="iniciais" required={true} />
                <SelectField
                    label="Tipo de Usuário"
                    options={options.tipo_usuario}
                    name="tipo_usuario"
                    required={true}
                />

                {+tipoUsuario === 2 && (
                    <>
                        <MultiSelectField
                            name="projeto"
                            label="Projetos"
                            options={options.projetos}
                        />
                    </>
                )}

                {id_usuario > 0 && (
                    <>
                        <SwitchField
                            label="Alterar senha?"
                            name="alterar_senha"
                        />
                        {watch("alterar_senha") === true && (
                            <>
                                <TextField
                                    autocomplete="new-password"
                                    label="Senha"
                                    name="password"
                                    required={true}
                                    type="password"
                                />
                                <TextField
                                    autocomplete="new-password"
                                    label="Senha (Repetir)"
                                    name="password_repeat"
                                    required={true}
                                    type="password"
                                />
                            </>
                        )}
                    </>
                )}

                {!id_usuario && (
                    <>
                        <TextField
                            autocomplete="new-password"
                            label="Senha"
                            name="password"
                            required={true}
                            type="password"
                        />
                        <TextField
                            autocomplete="new-password"
                            label="Senha (Repetir)"
                            name="password_repeat"
                            required={true}
                            type="password"
                        />
                    </>
                )}
            </Panel>
            <BotoesSubmit />
        </Formulario>
    );
}
