import ProjetoLayout from "../../../../../layout/projeto-layout";
import { Alert, Table } from "flowbite-react";
import React, { useEffect, useState } from "react";
import Projeto from "../../../../../services/projeto";
import { useParams } from "react-router";
import LoadingPage from "../../../../../layout/admin/loading-page";
import { useToast } from "../../../../../hooks/toast";
import ToastErro from "../../../../../layout/toast/toast-erro";
import AdminLayout from "../../../../../layout/admin-layout";
import Breadcrumbs from "../../../../../layout/admin/breadcrumbs";
import { route, urls } from "../../../../../urls";

export default function ProjetoErrosPage() {
    const toast = useToast();
    const { id_cliente, id_projeto } = useParams();

    const [loading, setLoading] = useState(true);
    const [erros, setErros] = useState([]);

    useEffect(() => {
        new Projeto(id_cliente, id_projeto)
            .erros()
            .then((data) => {
                setErros(data);
            })
            .catch(() => {
                toast(
                    <ToastErro msg="Ocorreu um erro ao processar a requisição" />,
                );
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <AdminLayout>
            <ProjetoLayout>
                <Breadcrumbs
                    title="Erros"
                    breadcrumbs={[
                        {
                            label: "Clientes",
                            url: route(urls.web.admin.cliente.listar),
                        },
                        {
                            label: "Projetos",
                            url: route(urls.web.admin.cliente.projeto.listar, {
                                id_cliente: id_cliente,
                            }),
                        },
                        {
                            label: "Erros",
                            url: "",
                        },
                    ]}
                />
                {loading && <LoadingPage />}
                {erros.length === 0 && (
                    <Alert color="success" className="m-4">
                        Nenhum erro encontrado
                    </Alert>
                )}
                {erros.length > 0 && (
                    <>
                        <div className="bg-white p-3 shadow m-4">
                            <Alert color="failure">
                                Corrija os erros indicados para poder exportar
                                esse projeto
                            </Alert>
                            <hr className="my-2" />
                            <Table striped>
                                <Table.Head>
                                    <Table.HeadCell>
                                        Parte/Subparte
                                    </Table.HeadCell>
                                    <Table.HeadCell>Posição</Table.HeadCell>
                                    <Table.HeadCell>Erro</Table.HeadCell>
                                </Table.Head>
                                <Table.Body>
                                    {erros.map((item, i) => {
                                        return (
                                            <Table.Row key={i}>
                                                <Table.Cell>
                                                    {item.subparte}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.posicao}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {item.erro}
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        </div>
                    </>
                )}
            </ProjetoLayout>
        </AdminLayout>
    );
}
