import React from "react";
import { Table } from "flowbite-react";
import { FaSortDown, FaSortUp } from "react-icons/fa";

const TabelaColuna = ({
    label,
    name,
    isHeader,
    size = "",
    data,
    callback,
    isOrderable,
    align = "L",
    onClick,
    ordering = "",
}) => {
    let classDefault = " ";
    if (size === "xs") {
        classDefault += " max-w-xs ";
    }

    // Alinhamento
    let classAlign = "";
    switch (align) {
        case "C":
            classAlign += " text-center ";
            break;
        case "R":
            classAlign += " text-right ";
            break;
        default:
            classAlign += " text-left ";
    }

    if (isHeader) {
        classDefault += " px-1 py-3 ";
        return (
            <Table.HeadCell
                onClick={onClick}
                className={
                    (isOrderable ? "cursor-pointer" : " ") +
                    " " +
                    classDefault +
                    classAlign
                }
            >
                <div>
                    <span>{label}</span>
                    {isOrderable && ordering != null && (
                        <span className="ml-2">
                            {ordering === "" ? (
                                <FaSortUp />
                            ) : ordering === "-" ? (
                                <FaSortDown />
                            ) : null}
                        </span>
                    )}
                </div>
            </Table.HeadCell>
        );
    }
    classDefault += " px-1 py-2 ";
    return (
        <Table.Cell className={classDefault + classAlign}>
            {callback ? callback(data) : data[name]}
        </Table.Cell>
    );
};

export default TabelaColuna;
