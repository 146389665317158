import React, { useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../../../layout/admin/loading-page";
import { Button } from "flowbite-react";
import ProjetoPreenchimento from "../../../../../services/projeto_preenchimento";
import { useNavigate } from "react-router";
import { useToast } from "../../../../../hooks/toast";
import ToastSucesso from "../../../../../layout/toast/toast-sucesso";
import { route, urls } from "../../../../../urls";
import ProjetoLayout from "../../../../../layout/projeto-layout";
import { formatAndSort } from "../../../../../utils/format";

const ProjetoPreenchimentoPage = () => {
    const { id_cliente, id_projeto } = useParams();
    const navigate = useNavigate();
    const form = useForm();
    const { handleSubmit, control, watch, setValue, getValues } = form;
    const addToast = useToast();
    const formRef = useRef();

    // field perfil
    const fieldPerfil = useFieldArray({ control, name: "perfis" });
    const appendPerfil = fieldPerfil.append;
    const fieldsPerfis = fieldPerfil.fields;

    // field parafuso
    const fieldParafuso = useFieldArray({ control, name: "parafusos" });

    // field parafuso degrau
    const fieldParafusoDeg = useFieldArray({ control, name: "degrau" });

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [options, setOptions] = useState([]);
    const [salvando, setSalvando] = useState(false);

    const getDadosPreenchimento = () => {
        new ProjetoPreenchimento(id_cliente, id_projeto)
            .show()
            .then((responseData) => {
                setData(responseData?.dados || []);
                setOptions(responseData.options || []);
                if (responseData?.dados?.perfis) {
                    responseData.dados?.perfis.forEach((item) => {
                        appendPerfil(item);
                    });
                }

                if (responseData?.dados?.parafusos) {
                    responseData.dados?.parafusos.forEach((item) => {
                        fieldParafuso.append(item);
                    });
                }

                if (responseData?.dados?.degrau) {
                    responseData.dados?.degrau.forEach((item) => {
                        fieldParafusoDeg.append(item);
                    });
                }

                appendPerfil(
                    { tipo: "", qualidade: "" },
                    { shouldFocus: false },
                );
                fieldParafuso.append(
                    {
                        parafuso_bitola: "",
                        parafuso_qualidade: "",
                        porca_quantidade: "",
                        porca_qualidade: "",
                        arruela_espessura: "",
                        arruela_quantidade: "",
                        arruela_qualidade: "",
                        arruela_pressao_quantidade: "",
                        arruela_pressao_qualidade: "",
                        palnuts_qualidade: "",
                    },
                    { shouldFocus: false },
                );

                fieldParafusoDeg.append(
                    {
                        parafuso_bitola: "",
                        porca_quantidade: "",
                        porca_qualidade: "",
                        arruela_espessura: "",
                        arruela_quantidade: "",
                        arruela_qualidade: "",
                        arruela_pressao_quantidade: "",
                        arruela_pressao_qualidade: "",
                        palnuts_qualidade: "",
                    },
                    { shouldFocus: false },
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getDadosPreenchimento();
    }, [id_projeto]);

    const onSubmit = (values) => {
        setSalvando(true);
        new ProjetoPreenchimento(id_cliente, id_projeto)
            .save(values)
            .then((data) => {
                addToast(<ToastSucesso msg="Salvo com sucesso" />);
                navigate(
                    route(urls.web.admin.cliente.projeto.subparte.listar, {
                        id_cliente: id_cliente,
                        id_projeto: id_projeto,
                    }),
                );
            });
    };

    if (loading) {
        return <LoadingPage />;
    }

    const handleTipoChange = (index, value) => {
        setValue(`perfis.${index}.tipo`, value);
        if (index === fieldsPerfis.length - 1) {
            appendPerfil({ tipo: "", qualidade: "" }, { shouldFocus: false }); // Adiciona nova linha vazia ao alterar a última linha
        }
    };

    return (
        <ProjetoLayout active="preenchimento" collapse={true}>
            <div className="p-5 text-sm tabela">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex items-center mb-4 p-3 bg-gray-300 justify-between">
                        <Button
                            ref={formRef}
                            type="submit"
                            color="success"
                            isProcessing={salvando}
                        >
                            Salvar
                        </Button>
                        <div className="flex space-x-6">
                            <Button
                                isProcessing={salvando}
                                onClick={() => {
                                    let confirm_ = window.confirm(
                                        "Deseja salvar alterações e atualizar elementos" +
                                            " de fixação?",
                                    );
                                    if (!confirm_) {
                                        return;
                                    }
                                    setValue("atualizar_elementos_fixacao", 1);
                                    formRef.current.click();
                                }}
                                type="button"
                                color="red"
                            >
                                Salvar e atualizar elementos de fixação
                            </Button>

                            <Button
                                color="failure"
                                className="w-48"
                                onClick={() => {
                                    let confirm_ = window.confirm(
                                        "Deseja descartar alterações?",
                                    );
                                    if (!confirm_) {
                                        return;
                                    }

                                    navigate(
                                        route(
                                            urls.web.admin.cliente.projeto
                                                .subparte.listar,
                                            {
                                                id_cliente: id_cliente,
                                                id_projeto: id_projeto,
                                            },
                                        ),
                                    );
                                }}
                            >
                                Descartar
                            </Button>
                        </div>
                    </div>

                    <hr className="my-3" />
                    <div className="flex space-x-6">
                        <div>
                            <h3>Qualidade Perfis</h3>
                            <table className="table-auto w-64">
                                <thead>
                                    <tr className="text-white">
                                        <th className=" bg-blue-600 border border-gray-900">
                                            TIPO PERFIL
                                        </th>
                                        <th className="bg-blue-700 border border-gray-900">
                                            QUALIDADE
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fieldsPerfis.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>
                                                <Controller
                                                    name={`perfis.${index}.tipo`}
                                                    control={control}
                                                    defaultValue={item.tipo}
                                                    render={({ field }) => (
                                                        <SelectLm
                                                            {...field}
                                                            options={options.perfis.map(
                                                                (opt) => ({
                                                                    value: opt.tipo,
                                                                    label: opt.tipo,
                                                                }),
                                                            )}
                                                            onChange={(
                                                                selected,
                                                            ) =>
                                                                handleTipoChange(
                                                                    index,
                                                                    selected
                                                                        .target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                            </td>
                                            <td>
                                                <Controller
                                                    name={`perfis.${index}.qualidade`}
                                                    control={control}
                                                    defaultValue={
                                                        item.qualidade
                                                    }
                                                    render={({ field }) => (
                                                        <SelectLm
                                                            {...field}
                                                            options={(
                                                                options.perfis.find(
                                                                    (opt) =>
                                                                        opt.tipo ===
                                                                        watch(
                                                                            `perfis.${index}.tipo`,
                                                                        ),
                                                                )?.qualidades ||
                                                                []
                                                            ).map((qual) => ({
                                                                value: qual,
                                                                label: qual,
                                                            }))}
                                                        />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <h3>Atalho</h3>
                            <table className="table-auto w-64">
                                <thead>
                                    <tr className="text-white">
                                        <th
                                            className=" bg-blue-600 border border-gray-900"
                                            colSpan={2}
                                        >
                                            ATALHO PARA D/E
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>D/E</td>
                                        <td>
                                            <Controller
                                                name={`atalho_d_e`}
                                                control={control}
                                                defaultValue={
                                                    data?.atalho_d_e ?? "D/E"
                                                }
                                                render={({ field }) => (
                                                    <InputLm
                                                        type="text"
                                                        className="w-full  input-tabela"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <h3>Qualidade Calços</h3>
                            <table className="table-auto w-64">
                                <thead>
                                    <tr className="text-white">
                                        <th className=" bg-blue-600 border border-gray-900">
                                            CALÇO
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Controller
                                                name={`qualidade_calcos`}
                                                control={control}
                                                defaultValue={
                                                    data?.qualidade_calcos ?? ""
                                                }
                                                render={({ field }) => (
                                                    <SelectLm
                                                        {...field}
                                                        options={options.qualidade_calcos.map(
                                                            (opt) => ({
                                                                value: opt,
                                                                label: opt,
                                                            }),
                                                        )}
                                                    />
                                                )}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <hr className="my-5" />

                    <h3>Parafuso</h3>
                    <table className="table-auto w-full">
                        <thead>
                            <tr className="text-white">
                                <th
                                    className="bg-blue-600 border border-gray-900"
                                    colSpan="2"
                                >
                                    PARAFUSO
                                </th>
                                <th
                                    className="bg-blue-800 border border-gray-900"
                                    colSpan="2"
                                >
                                    PORCAS
                                </th>
                                <th
                                    className="bg-blue-900 border border-gray-900"
                                    colSpan="3"
                                >
                                    ARRUELAS LISAS
                                </th>
                                <th
                                    className="bg-blue-600 border border-gray-900"
                                    colSpan="2"
                                >
                                    ARRUELAS PRESSÃO
                                </th>
                                <th
                                    className="bg-blue-700 border border-gray-900"
                                    colSpan="1"
                                >
                                    PALNUTS
                                </th>
                            </tr>
                            <tr className="text-white">
                                <th className="bg-blue-600 border border-gray-900">
                                    BITOLA
                                </th>
                                <th className="bg-blue-600 border border-gray-900">
                                    QUALIDADE
                                </th>
                                <th className="bg-blue-800 border border-gray-900">
                                    QTDE
                                </th>
                                <th className="bg-blue-800 border border-gray-900">
                                    QUALIDADE
                                </th>
                                <th className="bg-blue-900 border border-gray-900">
                                    ESPESSURA
                                </th>
                                <th className="bg-blue-900 border border-gray-900">
                                    QTDE
                                </th>
                                <th className="bg-blue-900 border border-gray-900">
                                    QUALIDADE
                                </th>
                                <th className="bg-blue-600 border border-gray-900">
                                    QTDE
                                </th>
                                <th className="bg-blue-600 border border-gray-900">
                                    QUALIDADE
                                </th>
                                <th className="bg-blue-700 border border-gray-900">
                                    QUALIDADE
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <TabelaParafusos
                                form={form}
                                options={options}
                                fieldParafuso={fieldParafuso}
                            />
                        </tbody>
                    </table>

                    <hr className="my-5" />
                    <h3>Parafuso Degrau</h3>
                    <table className="table-auto w-full">
                        <thead>
                            <tr className="text-white">
                                <th
                                    className="bg-blue-600 border border-gray-900"
                                    colSpan="1"
                                >
                                    PARAFUSO
                                </th>
                                <th
                                    className="bg-blue-800 border border-gray-900"
                                    colSpan="2"
                                >
                                    PORCAS
                                </th>
                                <th
                                    className="bg-blue-900 border border-gray-900"
                                    colSpan="3"
                                >
                                    ARRUELAS LISAS
                                </th>
                                <th
                                    className="bg-blue-600 border border-gray-900"
                                    colSpan="2"
                                >
                                    ARRUELAS PRESSÃO
                                </th>
                                <th
                                    className="bg-blue-700 border border-gray-900"
                                    colSpan="1"
                                >
                                    PALNUTS
                                </th>
                            </tr>
                            <tr className="text-white">
                                <th className="bg-blue-600 border border-gray-900">
                                    BITOLA
                                </th>
                                <th className="bg-blue-800 border border-gray-900">
                                    QTDE
                                </th>
                                <th className="bg-blue-800 border border-gray-900">
                                    QUALIDADE
                                </th>
                                <th className="bg-blue-900 border border-gray-900">
                                    ESPESSURA
                                </th>
                                <th className="bg-blue-900 border border-gray-900">
                                    QTDE
                                </th>
                                <th className="bg-blue-900 border border-gray-900">
                                    QUALIDADE
                                </th>
                                <th className="bg-blue-600 border border-gray-900">
                                    QTDE
                                </th>
                                <th className="bg-blue-600 border border-gray-900">
                                    QUALIDADE
                                </th>
                                <th className="bg-blue-700 border border-gray-900">
                                    QUALIDADE
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <TabelaParafusosDegrau
                                form={form}
                                options={options}
                                fieldParafusoDeg={fieldParafusoDeg}
                            />
                        </tbody>
                    </table>

                    <hr className="my-5" />
                </form>
            </div>
        </ProjetoLayout>
    );
};

function TabelaParafusosDegrau({ fieldParafusoDeg, form, options }) {
    const { watch, control, setValue } = form;

    const handleParafusoDegrauChange = (index, value) => {
        setValue(`degrau.${index}.parafuso_bitola`, value);
        if (index === fieldParafusoDeg.fields.length - 1) {
            fieldParafusoDeg.append(
                {
                    parafuso_bitola: "",
                    porca_quantidade: "",
                    porca_qualidade: "",
                    arruela_espessura: "",
                    arruela_quantidade: "",
                    arruela_qualidade: "",
                    arruela_pressao_quantidade: "",
                    arruela_pressao_qualidade: "",
                    palnuts_qualidade: "",
                },
                { shouldFocus: false },
            );
        }
    };

    return (
        <>
            {fieldParafusoDeg.fields.map((item, index) => {
                let espessuraArruela = watch(
                    `degrau.${index}.arruela_espessura`,
                );

                let qualidadeParafusos = Array.from(
                    new Set(
                        options.parafusos
                            .filter((x) => x.tipo === "PARAFUSO DEGRAU")
                            .map((opt) => opt.qualidade),
                    ),
                ).map((qualidade) => ({
                    value: qualidade,
                    label: qualidade,
                }));

                let qualidadePorcas = Array.from(
                    new Set(
                        options.parafusos
                            .filter((x) => x.tipo === "PORCA")
                            .map((opt) => opt.qualidade),
                    ),
                ).map((qualidade) => ({
                    value: qualidade,
                    label: qualidade,
                }));

                let qualidadePalnuts = Array.from(
                    new Set(
                        options.parafusos
                            .filter((x) => x.tipo === "PALNUT")
                            .map((opt) => opt.qualidade),
                    ),
                ).map((qualidade) => ({
                    value: qualidade,
                    label: qualidade,
                }));

                let espessurasArruelasLisas = Array.from(
                    new Set(
                        options.parafusos
                            .filter(
                                (x) =>
                                    x.tipo === "ARRUELA" && x.dimensao2 !== "",
                            )
                            .map((opt) => opt.dimensao2),
                    ),
                )
                    .sort((a, b) => a - b)
                    .map((espessura) => ({
                        value: espessura,
                        label: (+espessura).toFixed(2),
                    }));

                let qualidadeArruelas = Array.from(
                    new Set(
                        options.parafusos
                            .filter(
                                (x) =>
                                    x.tipo === "ARRUELA" &&
                                    x.dimensao2 === espessuraArruela,
                            )
                            .map((opt) => opt.qualidade),
                    ),
                ).map((qualidade) => ({
                    value: qualidade,
                    label: qualidade,
                }));

                let qualidadeArruelasPressao = Array.from(
                    new Set(
                        options.parafusos
                            .filter(
                                (x) =>
                                    x.tipo === "ARRUELA" && x.dimensao2 === "",
                            )
                            .map((opt) => opt.qualidade),
                    ),
                ).map((qualidade) => ({
                    value: qualidade,
                    label: qualidade,
                }));

                return (
                    <tr className="bg-gray-300" key={item.id}>
                        <td>
                            <Controller
                                name={`degrau.${index}.parafuso_bitola`}
                                control={control}
                                defaultValue={item.parafuso_bitola}
                                render={({ field }) => (
                                    <SelectLm
                                        {...field}
                                        showEmpty={true}
                                        onChange={(selected) =>
                                            handleParafusoDegrauChange(
                                                index,
                                                selected.target.value,
                                            )
                                        }
                                        options={qualidadeParafusos}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`degrau.${index}.porca_quantidade`}
                                control={control}
                                defaultValue={item.porca_quantidade}
                                render={({ field }) => (
                                    <InputLm
                                        className="w-full  input-tabela"
                                        {...field}
                                        type="number"
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`degrau.${index}.porca_qualidade`}
                                control={control}
                                defaultValue={item.porca_qualidade}
                                render={({ field }) => (
                                    <SelectLm
                                        {...field}
                                        options={qualidadePorcas}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`degrau.${index}.arruela_espessura`}
                                control={control}
                                defaultValue={item.arruela_espessura}
                                render={({ field }) => (
                                    <SelectLm
                                        {...field}
                                        options={espessurasArruelasLisas}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`degrau.${index}.arruela_quantidade`}
                                control={control}
                                defaultValue={item.arruela_quantidade}
                                render={({ field }) => (
                                    <InputLm
                                        className="w-full  input-tabela"
                                        type="number"
                                        {...field}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`degrau.${index}.arruela_qualidade`}
                                control={control}
                                defaultValue={item.arruela_qualidade}
                                render={({ field }) => (
                                    <SelectLm
                                        {...field}
                                        options={qualidadeArruelas}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`degrau.${index}.arruela_pressao_quantidade`}
                                control={control}
                                defaultValue={item.arruela_pressao_quantidade}
                                render={({ field }) => (
                                    <InputLm
                                        className="w-full  input-tabela"
                                        type="number"
                                        {...field}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`degrau.${index}.arruela_pressao_qualidade`}
                                control={control}
                                defaultValue={item.arruela_pressao_qualidade}
                                render={({ field }) => (
                                    <SelectLm
                                        {...field}
                                        options={qualidadeArruelasPressao}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`degrau.${index}.palnuts_qualidade`}
                                control={control}
                                defaultValue={item.palnuts_qualidade}
                                render={({ field }) => (
                                    <SelectLm
                                        {...field}
                                        options={qualidadePalnuts}
                                    />
                                )}
                            />
                        </td>
                    </tr>
                );
            })}
        </>
    );
}

function TabelaParafusos({ options, form, fieldParafuso }) {
    const { watch, control, setValue } = form;

    const handleParafusoBitolaChange = (index, value) => {
        setValue(`parafusos.${index}.parafuso_bitola`, value);
        if (index === fieldParafuso.fields.length - 1) {
            fieldParafuso.append(
                {
                    parafuso_bitola: "",
                    parafuso_qualidade: "",
                    porca_quantidade: "",
                    porca_qualidade: "",
                    arruela_espessura: "",
                    arruela_quantidade: "",
                    arruela_qualidade: "",
                    arruela_pressao_quantidade: "",
                    arruela_pressao_qualidade: "",
                    palnuts_qualidade: "",
                },
                { shouldFocus: false },
            );
        }
    };

    return (
        <>
            {fieldParafuso.fields.map((item, index) => {
                let bitola = watch(`parafusos.${index}.parafuso_bitola`);
                let espessuraArruela = watch(
                    `parafusos.${index}.arruela_espessura`,
                );

                let bitolas = Array.from(
                    new Set(
                        options.parafusos
                            .filter((x) => x.tipo === "PARAFUSO")
                            .map((opt) => opt.dimensao1),
                    ),
                ).map((bitola) => ({
                    value: bitola,
                    label: bitola,
                }));

                let qualidadeParafusos = Array.from(
                    new Set(
                        options.parafusos
                            .filter(
                                (x) =>
                                    x.tipo === "PARAFUSO" &&
                                    x.dimensao1 === bitola,
                            )
                            .map((opt) => opt.qualidade),
                    ),
                ).map((qualidade) => ({
                    value: qualidade,
                    label: qualidade,
                }));

                let qualidadePorcas = Array.from(
                    new Set(
                        options.parafusos
                            .filter(
                                (x) =>
                                    x.tipo === "PORCA" &&
                                    x.dimensao1 === bitola,
                            )
                            .map((opt) => opt.qualidade),
                    ),
                ).map((qualidade) => ({
                    value: qualidade,
                    label: qualidade,
                }));

                let qualidadePalnuts = Array.from(
                    new Set(
                        options.parafusos
                            .filter(
                                (x) =>
                                    x.tipo === "PALNUT" &&
                                    x.dimensao1 === bitola,
                            )
                            .map((opt) => opt.qualidade),
                    ),
                ).map((qualidade) => ({
                    value: qualidade,
                    label: qualidade,
                }));

                let espessurasArruelasLisas = Array.from(
                    new Set(
                        options.parafusos
                            .filter(
                                (x) =>
                                    x.tipo === "ARRUELA" &&
                                    x.dimensao1 === bitola &&
                                    x.dimensao2 !== "",
                            )
                            .map((opt) => opt.dimensao2),
                    ),
                )
                    .sort((a, b) => a - b)
                    .map((espessura) => ({
                        label: (+espessura).toFixed(2),
                        value: espessura,
                    }));

                let qualidadeArruelas = Array.from(
                    new Set(
                        options.parafusos
                            .filter(
                                (x) =>
                                    x.tipo === "ARRUELA" &&
                                    x.dimensao1 === bitola &&
                                    x.dimensao2 === espessuraArruela,
                            )
                            .map((opt) => opt.qualidade),
                    ),
                ).map((qualidade) => ({
                    value: qualidade,
                    label: qualidade,
                }));

                let qualidadeArruelasPressao = Array.from(
                    new Set(
                        options.parafusos
                            .filter(
                                (x) =>
                                    x.tipo === "ARRUELA" &&
                                    x.dimensao1 === bitola &&
                                    x.dimensao2 === "",
                            )
                            .map((opt) => opt.qualidade),
                    ),
                ).map((qualidade) => ({
                    value: qualidade,
                    label: qualidade,
                }));

                return (
                    <tr className="bg-gray-300" key={item.id}>
                        <td>
                            <Controller
                                name={`parafusos.${index}.parafuso_bitola`}
                                control={control}
                                defaultValue={item.parafuso_bitola}
                                render={({ field }) => (
                                    <SelectLm
                                        {...field}
                                        showEmpty={true}
                                        onChange={(selected) =>
                                            handleParafusoBitolaChange(
                                                index,
                                                selected.target.value,
                                            )
                                        }
                                        options={bitolas}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`parafusos.${index}.parafuso_qualidade`}
                                control={control}
                                defaultValue={item.parafuso_qualidade}
                                render={({ field }) => (
                                    <SelectLm
                                        {...field}
                                        options={qualidadeParafusos}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`parafusos.${index}.porca_quantidade`}
                                control={control}
                                defaultValue={item.porca_quantidade}
                                render={({ field }) => (
                                    <InputLm
                                        className="w-full  input-tabela"
                                        {...field}
                                        type="number"
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`parafusos.${index}.porca_qualidade`}
                                control={control}
                                defaultValue={item.porca_qualidade}
                                render={({ field }) => (
                                    <SelectLm
                                        {...field}
                                        options={qualidadePorcas}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`parafusos.${index}.arruela_espessura`}
                                control={control}
                                defaultValue={item.arruela_espessura}
                                render={({ field }) => (
                                    <SelectLm
                                        {...field}
                                        options={espessurasArruelasLisas}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`parafusos.${index}.arruela_quantidade`}
                                control={control}
                                defaultValue={item.arruela_quantidade}
                                render={({ field }) => (
                                    <InputLm
                                        className="w-full  input-tabela"
                                        type="number"
                                        {...field}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`parafusos.${index}.arruela_qualidade`}
                                control={control}
                                defaultValue={item.arruela_qualidade}
                                render={({ field }) => (
                                    <SelectLm
                                        {...field}
                                        options={qualidadeArruelas}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`parafusos.${index}.arruela_pressao_quantidade`}
                                control={control}
                                defaultValue={item.arruela_pressao_quantidade}
                                render={({ field }) => (
                                    <InputLm
                                        className="w-full  input-tabela"
                                        type="number"
                                        {...field}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`parafusos.${index}.arruela_pressao_qualidade`}
                                control={control}
                                defaultValue={item.arruela_pressao_qualidade}
                                render={({ field }) => (
                                    <SelectLm
                                        {...field}
                                        options={qualidadeArruelasPressao}
                                    />
                                )}
                            />
                        </td>
                        <td>
                            <Controller
                                name={`parafusos.${index}.palnuts_qualidade`}
                                control={control}
                                defaultValue={item.palnuts_qualidade}
                                render={({ field }) => (
                                    <SelectLm
                                        {...field}
                                        options={qualidadePalnuts}
                                    />
                                )}
                            />
                        </td>
                    </tr>
                );
            })}
        </>
    );
}

const SelectLm = ({ options, showEmpty = true, ...props }) => {
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();

            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1]?.focus();
        }
    };

    return (
        <select
            onKeyDown={handleKeyDown}
            className="w-full input-tabela"
            {...props}
        >
            {showEmpty && <option value="" />}
            {options.map((item, i) => {
                return (
                    <option key={i} value={item.value}>
                        {item.label}
                    </option>
                );
            })}
        </select>
    );
};

function InputLm({ children, ...props }) {
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();

            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 1]?.focus();
        }
    };

    return (
        <input onKeyDown={handleKeyDown} {...props}>
            {children}
        </input>
    );
}

export default ProjetoPreenchimentoPage;
