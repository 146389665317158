import { Button, Spinner } from "flowbite-react";
import ProjetoLayout from "../../../../../layout/projeto-layout";
import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFieldArray, useForm } from "react-hook-form";
import { useToast } from "../../../../../hooks/toast";
import ToastSucesso from "../../../../../layout/toast/toast-sucesso";
import { route, urls } from "../../../../../urls";
import ProjetoSubparteItens from "../../../../../services/projeto_subparte_itens";
import LoadingPage from "../../../../../layout/admin/loading-page";
import { FaPencilAlt } from "react-icons/fa";
import ProjetoSubparteEdicao from "../../../../../services/projeto_subparte_edicao";
import ProjetoSubparteRascunho from "../../../../../services/projeto_subparte_rascunho";
import TabelaFerragens from "pages/admin/cliente/projetos/subparte/components/tabela-ferragens";
import TabelaCalcos from "pages/admin/cliente/projetos/subparte/components/tabela-calcos";
import TabelaPalnuts from "pages/admin/cliente/projetos/subparte/components/tabela-palnuts";
import TabelaArruelas from "pages/admin/cliente/projetos/subparte/components/tabela-arruelas";
import TabelaPorcas from "pages/admin/cliente/projetos/subparte/components/tabela-porcas";
import TabelaParafusos from "pages/admin/cliente/projetos/subparte/components/tabela-parafusos";
import TabelaPerfis from "pages/admin/cliente/projetos/subparte/components/tabela-perfis";
import { BarraLateralSubparte } from "pages/admin/cliente/projetos/subparte/components/barra-lateral";

export default function SubparteItensPage() {
    const { id_cliente, id_projeto, id_subparte } = useParams();
    const navigate = useNavigate();
    const form = useForm();
    const addToast = useToast();
    const [loading, setLoading] = useState(true);
    const [situacaoEdicao, setSituacaoEdicao] = useState("");
    const [salvando, setSalvando] = useState(false);
    const [dadosEdicao, setDadosEdicao] = useState({});
    const [preenchimento, setPreenchimento] = useState({});
    const [itensCompletar, setItensCompletar] = useState({});
    const [camposDesabilitar, setCamposDesabilitar] = useState({});
    const [sidebar, setSidebar] = useState(false);
    const [campoOperacoes, setCampoOperacoes] = useState(true);
    const [campoRevisao, setCampoRevisao] = useState(false);

    let control = form.control;

    function verificarRepetidos(dados, chaves) {
        const seen = new Set();
        for (let item of dados) {
            const chave = chaves.map((chave) => item[chave] || "").join("|");
            if (seen.has(chave)) {
                return true;
            } else {
                seen.add(chave);
            }
        }
        return false;
    }

    function encontrarPosicoesRepetidas(items = []) {
        let posicoesMap = {};
        let posicoesRepetidas = [];

        items.forEach((item) => {
            const posicao = item.posicao;
            if (posicao) {
                if (posicoesMap[posicao]) {
                    posicoesMap[posicao]++;
                } else {
                    posicoesMap[posicao] = 1;
                }
            }
        });

        for (let posicao in posicoesMap) {
            if (posicoesMap[posicao] > 1) {
                posicoesRepetidas.push(posicao);
            }
        }

        return posicoesRepetidas;
    }

    const onSubmit = (values) => {
        let descartar = values["descartar"] ?? 0;
        if (!descartar) {
            let posicoesRepetidas = encontrarPosicoesRepetidas(
                values["perfis"],
            );
            if (posicoesRepetidas.length > 0) {
                alert(
                    "Existem posiçoes repetidas nesta subparte, corrija para poder salvar: " +
                        posicoesRepetidas.join(", "),
                );
                return;
            }

            let calcosRepetidos = verificarRepetidos(values["calcos"], [
                "dimensao1",
                "dimensao2",
                "qualidade",
            ]);
            if (calcosRepetidos) {
                alert(
                    "Existem calços repetidos na lista, corrija para poder salvar",
                );
                return;
            }

            let ferragensRepetidas = verificarRepetidos(values["ferragens"], [
                "qualidade",
            ]);
            if (ferragensRepetidas) {
                alert(
                    "Existem ferragens repetidas na lista, corrija para poder salvar",
                );
                return;
            }

            let arruelasRepetidas = verificarRepetidos(
                values["arruelas"].filter((x) => !x.flag_auto_preenchimento),
                ["dimensao1", "dimensao2", "qualidade"],
            );
            if (arruelasRepetidas) {
                alert(
                    "Existem arruelas repetidas na lista, corrija para poder salvar",
                );
                return;
            }

            let palnutRepetidos = verificarRepetidos(
                values["palnuts"].filter((x) => !x.flag_auto_preenchimento),
                ["dimensao1", "qualidade"],
            );
            if (palnutRepetidos) {
                alert(
                    "Existem palnuts repetidos na lista, corrija para poder salvar",
                );
                return;
            }

            let porcasRepetidos = verificarRepetidos(
                values["porcas"].filter((x) => !x.flag_auto_preenchimento),
                ["dimensao1", "qualidade"],
            );
            if (porcasRepetidos) {
                alert(
                    "Existem porcas repetidas na lista, corrija para poder salvar",
                );
                return;
            }

            let parafusosRepetidos = verificarRepetidos(values["parafusos"], [
                "dimensao1",
                "dimensao2",
                "qualidade",
            ]);
            if (parafusosRepetidos) {
                alert(
                    "Existem parafusos repetidas na lista, corrija para poder salvar",
                );
                return;
            }
        }

        setSalvando(true);
        new ProjetoSubparteItens(id_cliente, id_projeto, id_subparte)
            .save(values)
            .then((data) => {
                if (!(values["descartar"] ?? 0)) {
                    addToast(<ToastSucesso msg="Salvo com sucesso" />);
                }
                navigate(
                    route(urls.web.admin.cliente.projeto.subparte.listar, {
                        id_cliente: id_cliente,
                        id_projeto: id_projeto,
                    }),
                );
            })
            .catch(() => {
                alert(
                    "Ocorreu um erro desconhecido ao salvar os itens da subparte. Verifique sua conexão com a internet.",
                );
                setSalvando(false);
            });
    };

    const fieldPerfil = useFieldArray({ control, name: "perfis" });
    const fieldParafusos = useFieldArray({ control, name: "parafusos" });
    const fieldArruelas = useFieldArray({ control, name: "arruelas" });
    const fieldPorcas = useFieldArray({ control, name: "porcas" });
    const fieldPalnuts = useFieldArray({ control, name: "palnuts" });
    const fieldCalcos = useFieldArray({ control, name: "calcos" });
    const fieldFerragens = useFieldArray({ control, name: "ferragens" });

    useEffect(() => {
        setLoading(true);
        new ProjetoSubparteItens(id_cliente, id_projeto, id_subparte)
            .get()
            .then(
                ({
                    itens,
                    modo_edicao,
                    situacao_edicao,
                    campos_desabilitar,
                    itens_completar,
                    auto_preenchimento,
                }) => {
                    setSituacaoEdicao(situacao_edicao);
                    setDadosEdicao(modo_edicao);
                    setPreenchimento(auto_preenchimento);
                    setItensCompletar(itens_completar);
                    setCamposDesabilitar(campos_desabilitar);

                    let rascunho = false;
                    if (situacao_edicao === "editando" && modo_edicao?.dados) {
                        rascunho = true;
                        itens = modo_edicao?.dados;
                    }

                    itens.perfis.forEach((item, i) => {
                        fieldPerfil.append(item, { shouldFocus: false });
                    });
                    itens.parafusos.forEach((item, i) => {
                        fieldParafusos.append(item, { shouldFocus: false });
                    });
                    itens.porcas.forEach((item, i) => {
                        fieldPorcas.append(item, { shouldFocus: false });
                    });
                    itens.arruelas.forEach((item, i) => {
                        fieldArruelas.append(item, { shouldFocus: false });
                    });
                    itens.calcos.forEach((item, i) => {
                        fieldCalcos.append(item, { shouldFocus: false });
                    });
                    itens.ferragens.forEach((item, i) => {
                        fieldFerragens.append(item, { shouldFocus: false });
                    });
                    itens.palnuts.forEach((item, i) => {
                        fieldPalnuts.append(item, { shouldFocus: false });
                    });

                    if (!rascunho) {
                        fieldPerfil.append({}, { shouldFocus: false });
                        fieldArruelas.append({}, { shouldFocus: false });
                        fieldCalcos.append({}, { shouldFocus: false });
                        fieldParafusos.append({}, { shouldFocus: false });
                        fieldFerragens.append({}, { shouldFocus: false });
                        fieldPorcas.append({}, { shouldFocus: false });
                        fieldPalnuts.append({}, { shouldFocus: false });
                    }
                },
            )
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <ProjetoLayout collapse={true}>
                <LoadingPage />
            </ProjetoLayout>
        );
    }

    const salvarRascunho = () => {
        return new ProjetoSubparteRascunho(
            id_cliente,
            id_projeto,
            id_subparte,
        ).post(form.getValues());
    };

    const iniciarEdicao = () => {
        new ProjetoSubparteEdicao(id_cliente, id_projeto, id_subparte)
            .post()
            .then(() => {
                setSituacaoEdicao("editando");
            })
            .catch(() => {
                alert(
                    "Ocorreu um erro, verifique a conexão com a internet e atualize a página",
                );
            });
    };

    return (
        <>
            <BarraLateralSubparte
                show={sidebar}
                close={() => setSidebar(false)}
            />

            <ProjetoLayout collapse={true}>
                <form
                    onSubmit={
                        situacaoEdicao === "editando"
                            ? form.handleSubmit(onSubmit)
                            : undefined
                    }
                >
                    {situacaoEdicao === "editando" && (
                        <div className="flex items-center mb-4 p-2 bg-gray-200 justify-between">
                            <div className="flex gap-4">
                                <Button
                                    color="success"
                                    size="sm"
                                    type={salvando ? "button" : "submit"}
                                    isProcessing={salvando}
                                >
                                    {salvando
                                        ? "Salvando..."
                                        : "Concluir alterações"}
                                </Button>
                                <Button
                                    size="sm"
                                    color="blue"
                                    onClick={() => setSidebar(true)}
                                >
                                    Informações
                                </Button>
                            </div>

                            <BarraSalvarRascunho
                                salvar={() => salvarRascunho()}
                                situacaoEdicao={situacaoEdicao}
                            />

                            <div className="flex space-x-4">
                                <div className="flex items-center">
                                    <input
                                        id="checkbox-1"
                                        type="checkbox"
                                        checked={campoOperacoes}
                                        onChange={() =>
                                            setCampoOperacoes(!campoOperacoes)
                                        }
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                    />
                                    <label
                                        htmlFor="checkbox-1"
                                        className="ml-2 text-sm font-medium text-gray-900"
                                    >
                                        Operações
                                    </label>
                                </div>

                                <div className="flex items-center">
                                    <input
                                        id="checkbox-2"
                                        type="checkbox"
                                        checked={campoRevisao}
                                        onChange={() =>
                                            setCampoRevisao(!campoRevisao)
                                        }
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                    />
                                    <label
                                        htmlFor="checkbox-2"
                                        className="ml-2 text-sm font-medium text-gray-900"
                                    >
                                        Revisão
                                    </label>
                                </div>
                            </div>

                            <div className="flex space-x-6">
                                <Button
                                    onClick={() => {
                                        let confirm_ = window.confirm(
                                            "Deseja descartar alterações dos itens da subparte?",
                                        );
                                        if (confirm_) {
                                            onSubmit({ descartar: 1 });
                                        }
                                    }}
                                    color="failure"
                                    size="sm"
                                    className="w-48 items-center"
                                >
                                    Descartar Alterações
                                </Button>
                            </div>
                        </div>
                    )}

                    {situacaoEdicao === "livre" && (
                        <div className="mb-4 p-2 bg-gray-200 flex gap-4">
                            <Button
                                color="light"
                                size="sm"
                                onClick={iniciarEdicao}
                            >
                                <FaPencilAlt />{" "}
                                <span className="ml-2">
                                    INICIAR EDIÇÃO DA SUBPARTE
                                </span>
                            </Button>

                            <Button
                                size="sm"
                                color="blue"
                                onClick={() => setSidebar(true)}
                            >
                                Informações
                            </Button>
                        </div>
                    )}

                    {situacaoEdicao === "bloqueado" && (
                        <div className="mb-4 p-2 bg-gray-800 text-white flex justify-center">
                            <span className="text-center font-medium">
                                O usuário {dadosEdicao?.usuario?.nome} está
                                editando esta subparte.
                            </span>
                        </div>
                    )}

                    <fieldset
                        disabled={salvando || situacaoEdicao !== "editando"}
                    >
                        <div className="p-2 text-sm">
                            <div className="flex tabela">
                                <div className="flex-1">
                                    <TabelaPerfis
                                        camposDesabilitar={camposDesabilitar}
                                        itensCompletar={itensCompletar}
                                        preenchimento={preenchimento}
                                        campoOperacoes={campoOperacoes}
                                        campoRevisao={campoRevisao}
                                        fieldPerfil={fieldPerfil}
                                        form={form}
                                    />

                                    <div className="my-3">
                                        <hr />
                                    </div>

                                    <TabelaParafusos
                                        preenchimento={preenchimento}
                                        campoRevisao={campoRevisao}
                                        fieldParafusos={fieldParafusos}
                                        form={form}
                                    />

                                    <div className="my-3">
                                        <hr />
                                    </div>

                                    <TabelaPorcas
                                        preenchimento={preenchimento}
                                        campoRevisao={campoRevisao}
                                        fieldPorcas={fieldPorcas}
                                        form={form}
                                    />

                                    <div className="my-3">
                                        <hr />
                                    </div>

                                    <TabelaArruelas
                                        preenchimento={preenchimento}
                                        campoRevisao={campoRevisao}
                                        fieldArruelas={fieldArruelas}
                                        form={form}
                                    />

                                    <div className="my-3">
                                        <hr />
                                    </div>

                                    <TabelaPalnuts
                                        preenchimento={preenchimento}
                                        campoRevisao={campoRevisao}
                                        fieldPalnuts={fieldPalnuts}
                                        form={form}
                                    />

                                    <div className="my-3">
                                        <hr />
                                    </div>

                                    <TabelaCalcos
                                        preenchimento={preenchimento}
                                        campoRevisao={campoRevisao}
                                        fieldCalcos={fieldCalcos}
                                        form={form}
                                    />

                                    <div className="my-3">
                                        <hr />
                                    </div>

                                    <TabelaFerragens
                                        preenchimento={preenchimento}
                                        campoRevisao={campoRevisao}
                                        fieldFerragens={fieldFerragens}
                                        form={form}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    {situacaoEdicao === "editando" && (
                        <div className="flex items-center mb-4 p-2 bg-gray-200 justify-between">
                            <Button
                                color="success"
                                size="sm"
                                type={salvando ? "button" : "submit"}
                                isProcessing={salvando}
                            >
                                {salvando
                                    ? "Salvando..."
                                    : "Concluir alterações"}
                            </Button>
                        </div>
                    )}
                </form>
            </ProjetoLayout>
        </>
    );
}

const BarraSalvarRascunho = ({ salvar, situacaoEdicao }) => {
    const [status, setStatus] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    const saveDraft = useCallback(async () => {
        if (situacaoEdicao !== "editando") {
            return;
        }

        setIsSaving(true);
        setStatus("Salvando rascunho");
        try {
            // Substitua com a lógica de salvamento real
            await salvar();
            setIsSaving(false);
            setStatus("Rascunho salvo");
        } catch (error) {
            setIsSaving(false);
            setStatus(
                "Falha ao salvar o rascunho - verifique a conexão com a internet",
            );
        }
    }, [situacaoEdicao]);

    useEffect(() => {
        saveDraft();
        const interval = setInterval(saveDraft, 15000);
        return () => clearInterval(interval);
    }, [saveDraft, situacaoEdicao]);

    if (situacaoEdicao !== "editando") {
        return <></>;
    }

    return (
        <div className="text-gray-700">
            <div className="flex items-center justify-center font-medium">
                {isSaving ? (
                    <>
                        Salvando rascunho <Spinner size="sm" className="ml-2" />
                    </>
                ) : (
                    <span>{status}</span>
                )}
            </div>
        </div>
    );
};
