import AdminLayout from "layout/admin-layout";
import Breadcrumbs from "layout/admin/breadcrumbs";
import React from "react";
import Panel from "../../../../../layout/form/panel";
import TextField from "../../../../../layout/form/text-field";
import { route, urls } from "../../../../../urls";
import { useParams } from "react-router";
import Formulario from "../../../../../layout/form/form";
import BotoesSubmit from "../../../../../layout/form/botoes-submit";
import { CrudProvider } from "../../../../../context/crud-context";
import ProjetoSubparte from "../../../../../services/projeto_subparte";
import ProjetoLayout from "../../../../../layout/projeto-layout";
import useCrud from "../../../../../hooks/crud";

export default function SubparteCrudPage() {
    const { id_cliente, id_projeto, id_subparte } = useParams();

    let source = new ProjetoSubparte(id_cliente, id_projeto, id_subparte);

    return (
        <AdminLayout>
            <ProjetoLayout active="subpartes">
                {" "}
                <Breadcrumbs
                    title="Subpartes"
                    breadcrumbs={[
                        {
                            label: "Clientes",
                            url: route(urls.web.admin.cliente.listar),
                        },
                        {
                            label: "Projetos",
                            url: route(urls.web.admin.cliente.projeto.listar, {
                                id_cliente: id_cliente,
                            }),
                        },
                        {
                            label: "Subpartes",
                            url: route(
                                urls.web.admin.cliente.projeto.subparte.listar,
                                {
                                    id_cliente: id_cliente,
                                    id_projeto: id_projeto,
                                },
                            ),
                        },
                        {
                            label:
                                id_subparte > 0
                                    ? "Editar Subparte"
                                    : "Criar Subparte",
                            url: "",
                        },
                    ]}
                />
                <div className="container mx-auto max-w-5xl">
                    <CrudProvider
                        param="id_subparte"
                        id={id_subparte}
                        source={source}
                    >
                        <Form />
                    </CrudProvider>
                </div>
            </ProjetoLayout>
        </AdminLayout>
    );
}

function Form() {
    const { options } = useCrud();

    return (
        <Formulario>
            <Panel>
                <TextField autoFocus label="Nome" name="nome" required={true} />
                <TextField label="Desenho" name="desenho" required={false} />
                <TextField label="Revisão" name="revisao" required={false} />
                <TextField
                    label="Sigla"
                    name="sigla"
                    required={false}
                    disabled={options.flag_sigla_bloqueado}
                />
            </Panel>
            <BotoesSubmit />
        </Formulario>
    );
}
